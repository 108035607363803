/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './../Utils/EventsEmitter'
import { Config } from 'evr'
import Logger from 'Utils/Logger'
import Utils from 'Utils/Utils'

// reuse geometries for better performance
let planeGeometry;
let circleGeometry;

export default class Icon extends EventsEmitter {
  constructor(props) {
    super(props);
    this._project = props.project;
    this._disposable = [];
    this.$l = new Logger('WidgetIcon');
    this.style = props.style;
    this.resourceId = props.resourceId;
    this.ratio = Config.player.scaleFactor.image * 2;
    this.margin = Config.player.widgets.icon.margin * this.ratio;
    this.size = Config.player.widgets.icon.size * this.ratio;
    this.$ = new THREE.Group();
    this.$.renderOrder = Config.player.renderOrder.icon;
    this.$.name = 'WidgetIcon';

    this.createBackgroundMesh();
    this.createIconMesh();
    this.update();
  }
  update() {
    this.isReady = this._project.getResource({id: this.resourceId}, {id: 'plus'}).then((img) => {
      this.iconImg = img;
      this.drawIcon();
    }, (e) => {
      this.$l.error('Couldn\'t load icon with id: ' + e);
    });
  }
	createIconMesh() {
    this.iconCanvas = Utils.$({element: 'canvas'});
    this.iconCanvas.width = this.iconCanvas.height = this.size;
    this.iconCanvas.style.width = this.iconCanvas.style.height = (this.size / this.ratio) + 'px';
    this.iconCtx = this.iconCanvas.getContext('2d');

    if (!planeGeometry) {
      planeGeometry = new THREE.PlaneGeometry(this.size / this.ratio / 4, this.size / this.ratio / 4, 32, 32);
    }

    this._geometry = planeGeometry;
    this._texture = new THREE.Texture(this.iconCanvas);
    this._material = new THREE.MeshBasicMaterial({
      map: this._texture,
      color: '#fff',
      transparent: true,
      opacity: 1,
      depthTest: false,
      depthWrite: false
    });

    this._disposable.push(this._geometry);
    this._disposable.push(this._texture);
    this._disposable.push(this._material);

    this.iconMesh = new THREE.Mesh(this._geometry, this._material);
    this.iconMesh.renderOrder = Config.player.renderOrder.icon;
    this.iconMesh.name = 'WidgetIcon';
    this.$.add(this.iconMesh);
  }
  createBackgroundMesh() {
    var radius = (this.size - 2 * this.margin) / this.ratio / 8;

    if (!circleGeometry) {
      circleGeometry = new THREE.CircleGeometry(radius , 32 );
    }

    this._backgroundMeshGeometry = circleGeometry;

    this._backgroundMeshMaterial = new THREE.MeshBasicMaterial( {
      color: Config.player.widgets.themes[this.style.theme].background,
      transparent: true,
      opacity: 0.8,
      depthWrite: false,
      depthTest: false,
    } );

    this._disposable.push(this._backgroundMeshMaterial);
    this._disposable.push(this._backgroundMeshMaterial);

    this.backgroundMesh = new THREE.Mesh( this._backgroundMeshGeometry, this._backgroundMeshMaterial );
    this.backgroundMesh.name = 'WidgetIconBackground';
    this.backgroundMesh.renderOrder = Config.player.renderOrder.iconBackground;
    this.$.add(this.backgroundMesh);
  }
	drawIcon(progress) {
    let iconProgress = progress || 0,
      alpha = (iconProgress < 0.1 ? iconProgress * 10 : 1);
    iconProgress *= 0.95;

    this.iconCtx.clearRect(0, 0, this.size, this.size);
    this.iconCtx.globalCompositeOperation = 'source-over';
    // Icon
    this.iconCtx.drawImage(this.iconImg, this.margin, this.margin, this.size - 2 * this.margin, this.size - 2 * this.margin);
    // Save current rotation state and translate canvas origin
    this.iconCtx.save();
    this.iconCtx.translate(this.size / 2, this.size / 2);
    // Rotate
    this.iconCtx.rotate(-2 * Math.PI * iconProgress);
    // Small dot
    this.iconCtx.beginPath();
    this.iconCtx.arc(this.size / 2 - (this.margin / 2) - 1 * this.ratio, -this.margin, 3 * this.ratio, 0, 2 * Math.PI, false);
    this.iconCtx.fillStyle = 'rgba(255, 255, 255, ' + alpha + ')';
    this.iconCtx.fill();
    this.iconCtx.closePath();
    // Border
    this.iconCtx.beginPath();
    this.iconCtx.arc(0, 0, this.size / 2 - (this.margin / 2), iconProgress * 2 * Math.PI, 2 * Math.PI, true);
    this.iconCtx.lineWidth = 1.5 * this.ratio;
    this.iconCtx.strokeStyle = 'rgba(255, 255, 255, ' + alpha + ')';
    this.iconCtx.stroke();
    this.iconCtx.restore();
    //Fill icon with color
    this.iconCtx.globalCompositeOperation = 'source-atop';
    this.iconCtx.fillStyle = this.style.color;
    this.iconCtx.fillRect(0, 0, this.size, this.size);

    this._texture.needsUpdate = true;
  }
  setTheme(theme) {
    this.style.theme = theme;
    this._backgroundMeshMaterial.color.set(Config.player.widgets.themes[theme].iconBackground);
  }
  remove() {
    while(this._disposable.length) {
      const disposable = this._disposable.pop();
      disposable.dispose();
    }
    this.iconCanvas.width = 0;
    this.iconCanvas.height = 0;
    delete this.iconCanvas;
  }
}

