/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

/**
 * @author dmarcos / https://github.com/dmarcos
 * @author mrdoob / http://mrdoob.com
 */
export default class VRControls {
  constructor( camera, onError ) {
    this._camera = camera;
    this.standingMatrix = new THREE.Matrix4();
    // var frameData = null;

    if ( 'VRFrameData' in window ) {
      // eslint-disable-next-line no-undef
      this.frameData = new VRFrameData();
    }

    let gotVRDisplays = ( displays ) => {
      this.vrDisplays = displays;
      if ( displays.length > 0 ) {
        this.vrDisplay = displays[ 0 ];
      } else {
        if ( onError ) onError( 'VR input not available.' );
      }
    };

    if ( navigator.getVRDisplays ) {
      navigator.getVRDisplays().then( gotVRDisplays ).catch ( function () {
        console.warn( 'THREE.VRControls: Unable to get VR Displays' );
      } );
    }

    // the Rift SDK returns the position in meters
    // this scale factor allows the user to define how meters
    // are converted to scene units.

    this.scale = 1;

    // If true will use "standing space" coordinate system where y=0 is the
    // floor and x=0, z=0 is the center of the room.
    this.standing = false;

    // Distance from the users eyes to the floor in meters. Used when
    // standing=true but the VRDisplay doesn't provide stageParameters.
    this.userHeight = 1.6;
  }
  getVRDisplay() {
    return this.vrDisplay;
  }
  setVRDisplay( value ) {
    this.vrDisplay = value;
  }
  getVRDisplays() {
    console.warn( 'THREE.VRControls: getVRDisplays() is being deprecated.' );
    return this.vrDisplays;
  }
  getStandingMatrix() {
    return this.standingMatrix;
  }
  getOrientation() {
    var pose;

    if ( this.vrDisplay.getFrameData ) {
      this.vrDisplay.getFrameData( this.frameData );
      pose = this.frameData.pose;
    } else if ( this.vrDisplay.getPose ) {
      pose = this.vrDisplay.getPose();
    }

    if ( pose.orientation !== null ) {
      return pose.orientation;
    }
  }
  update() {
    if ( this.vrDisplay ) {
      var pose;

      if ( this.vrDisplay.getFrameData ) {
        this.vrDisplay.getFrameData( this.frameData );
        pose = this.frameData.pose;
      } else if ( this.vrDisplay.getPose ) {
        pose = this.vrDisplay.getPose();
      }

      if ( pose.orientation !== null ) {
        this._camera.quaternion.fromArray( pose.orientation );
      }

      if ( pose.position !== null ) {
        this._camera.position.fromArray( pose.position );
      } else {
        this._camera.position.set( 0, 0, 0 );
      }

      if ( this.standing ) {
        if ( this.vrDisplay.stageParameters ) {
          this._camera.updateMatrix();
          this.standingMatrix.fromArray( this.vrDisplay.stageParameters.sittingToStandingTransform );
          this._camera.applyMatrix( this.standingMatrix );
        } else {
          this._camera.position.setY( this._camera.position.y + this.userHeight );
        }
      }

      this._camera.position.multiplyScalar( this.scale );
    }
  }
  resetPose() {
    if ( this.vrDisplay ) {
      this.vrDisplay.resetPose();
    }
  }
  resetSensor() {
    console.warn( 'THREE.VRControls: .resetSensor() is now .resetPose().' );
    this.resetPose();
  }
  zeroSensor() {
    console.warn( 'THREE.VRControls: .zeroSensor() is now .resetPose().' );
    this.resetPose();
  }
  dispose() {
    this.vrDisplay = null;
  }
}

