/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Widget from './Widget';
import View from './View';
import { Config } from 'evr';

// reuse material for better performance
let lineMaterial;

export default class BannerWidget extends Widget {
  constructor(props) {
    super(props);

    this._meshContent;

    this.renderOrder = Config.player.renderOrder.banner + props.getRenderOrderInc();
    this.createContent();
    this.createHighlightMesh();
    this._state.isReady = Promise.resolve();
  }
  createHighlightMesh() {
    if (this.getHighlightBounds) {
      let visible = false;

      if (this.highlightMesh) {
        visible = this.highlightMesh.visible;
        this._mesh.remove(this.highlightMesh);
      }

      this.highlightMesh = new THREE.Group();
      var bounds = this.getHighlightBounds();

      this.highlightMesh.renderOrder = Config.player.renderOrder.highlight;

      if (!lineMaterial) {
        lineMaterial = new THREE.LineBasicMaterial({
          color: Config.player.widgets.highlightColor,
          linewidth: 2,
          transparent: true,
          depthTest: false
        });
      }

      var lineGeometry = new THREE.Geometry();
      lineGeometry.vertices.push(
        new THREE.Vector3(bounds.max.x, bounds.max.y, 0),
        new THREE.Vector3(bounds.min.x, bounds.max.y, 0),
        new THREE.Vector3(bounds.min.x, bounds.min.y, 0),
        new THREE.Vector3(bounds.max.x, bounds.min.y, 0),
        new THREE.Vector3(bounds.max.x, bounds.max.y, 0)
      );

      var line = new THREE.Line(lineGeometry, lineMaterial);
      line.renderOrder = Config.player.renderOrder.highlight;
      this.highlightMesh.add(line);
      this.highlightMesh.visible = visible;
      this._mesh.add(this.highlightMesh);
    }
  }
  getHighlightBounds() {
    this._meshContent.geometry.computeBoundingBox();
    return this._meshContent.geometry.boundingBox;
  }
  createContent() {
    if (this._meshContent) {
      this._mesh.remove(this._meshContent);
    }
    const margin = {
      top: 8,
      left: 0,
      right: 0,
      bottom: 0,
      ...this._data.details.margin,
    };

    const backgroundColor = (this._data.details.background && 
      (this._data.details.backgroundColor || Config.player.widgets.themes[this._data.details.theme].background)
    );

    this._view = new View({
      style: {
        background: backgroundColor,
        borderRadius: 2,
        fontSize: this._data.details.fontSize,
        font: this._data.details.font,
        decorations: [],
        alignement: this._data.details.textAlignment,
        color: this._data.details.color,
        margin,
      },
      elements: [{type: 'text', text: this._data.details.text}],
      width: (this._data.details.size && this._data.details.size.width ? this._data.details.size.width : 2000)
    });
    this._view._mesh.renderOrder = this.renderOrder;
    this._intersectArray.push(this._view._mesh);
    this._meshContent = this._view._mesh;
    this._mesh.add(this._view._mesh);

    return Promise.resolve();
  }

  remove() {
    super.remove();
    this._view.remove();
  }
}

