/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Logger from 'Utils/Logger';
import { Config } from 'evr';

function offloadVideo(video) {
  const src = video.src;
  video.removeAttribute('src');
  video.load();
  video.setAttribute('src', src);
}

export default class Sphere {
  constructor(settings) {
    this.$l = new Logger('Sphere'),
    this._project = settings.project;
    this.videoSphericalEnabled = settings.videoSphericalEnabled;
    this.$ = new THREE.Group();
    this.$.name = 'SphereContainer';

    let geometry = new THREE.SphereGeometry(Config.sphereRadius, 60, 60);
    let material = new THREE.MeshBasicMaterial({
      overdraw: 0.5,
      transparent: true,
      opacity: 1,
      depthWrite: false,
      depthTest: false,
    });
    this._sphereMesh = new THREE.Mesh(geometry, material);
    this._sphereMesh.scale.x = -1;
    this._sphereMesh.name = "Sphere";
    this._sphereMesh.rotation.set(0, (90.0 * Math.PI / 180), 0);
    this._sphereMesh.renderOrder = 0;
    let geometry2 = new THREE.SphereGeometry(Config.sphereRadius, 60, 60);
    let material2 = new THREE.MeshBasicMaterial({
      overdraw: 0.5,
      transparent: true,
      opacity: 1,
      depthWrite: false,
      depthTest: false,
    });
    this._stereoSphereMesh = new THREE.Mesh(geometry2, material2);
    this._stereoSphereMesh.scale.x = -1;
    this._stereoSphereMesh.name = "StereoSphere";
    this._stereoSphereMesh.rotation.set(0, (90.0 * Math.PI / 180), 0);
    this._stereoSphereMesh.renderOrder = 0;

    //Create overlay mesh
    var overlayGeometry = new THREE.SphereGeometry(Config.sphereRadius, 60, 60),
      overlayMaterial = new THREE.MeshBasicMaterial({
        color: 0xFFFFFF,
        opacity: 0,
        transparent: true,
        side: THREE.BackSide,
        depthWrite: false,
        depthTest: false,
      });

    this._overlayMesh = new THREE.Mesh(overlayGeometry, overlayMaterial);
    this._overlayMesh.name = 'Overlay';
    this._overlayMesh.renderOrder = Config.player.renderOrder.overlay;

    this.$.add(this._overlayMesh);
    this.$.add(this._stereoSphereMesh);
    this.$.add(this._sphereMesh);

    this._currentSphere = {
      resourceId: null,
      stereoResourceId: null
    };
  }
  updateVideoTexture(texture) {
    const video = texture.image;
    const canPlayThrough = video.readyState === 4 || video.readyState === 3; // 'HAVE_ENOUGH_DATA' || 'can watch something'

    video.muted = !this.videoSphericalEnabled;

    const play = () => {
      video.play().then(()=> {
        if (!this.videoSphericalEnabled) {
          video.pause();
        }
      }, () => {
        this.$l.error('Cannot play video sphere');
      });
    };

    if (canPlayThrough) {
      play();
      return;
    }

    const onCanPlay = () => {
      video.oncanplaythrough = () => {};
      video.onloadedmetadata = () => {};
      video.oncanplay = () => {};
      play();
    };

    video.oncanplaythrough = onCanPlay;
    video.onloadedmetadata = onCanPlay;
    video.oncanplay = onCanPlay;

    video.setAttribute('preload', 'auto');
  }
  updateTexture(type, texture, resourceId) {
    const isVideoTexture = texture && texture.image instanceof HTMLVideoElement;
    let mesh = (type=='MESH'?this._sphereMesh:this._stereoSphereMesh);

    if (
      (type=='MESH' && this._currentSphere.resourceId!==resourceId) ||
      (type=='STEREOMESH' && this._currentSphere.stereoResourceId!==resourceId)
    ) {
      return;
    }

    if (mesh.material.map && mesh.material.map instanceof THREE.Texture) {
      mesh.material.map.dispose();
    }
    mesh.material.map = texture;
    mesh.material.needsUpdate = true;

    if (!isVideoTexture) { return; }

    return this.updateVideoTexture(texture);
  }
  updateSphere(type, resourceId, isVideoSphere = false) {
    let fullsize = this._project.getResource({id: resourceId, size: 'fullsize'}),
      thumbnail = this._project.getResource({id: resourceId, size: 'thumbnail'}),
      fullsizeSwitched = false,
      thumbnailSwitched = false;

    thumbnail.then((resource) => {
      if (!fullsizeSwitched) {
        thumbnailSwitched = true;
        this.updateTexture(type, resource, resourceId);
      }
    }, () => {
      this.$l.warn('Thumbnail didn\'t load');
    });

    // For video sphere just use thumbnail size. In other cases fetch fullsize too
    if (isVideoSphere) { return; }

    fullsize.then((resource) => {
      fullsizeSwitched = true;
      this.updateTexture(type, resource, resourceId);
    }, () => {
      this.$l.warn('Sphere didn\'t load');
      if (!thumbnailSwitched) {
        const resourceId = 'sphereBroken';
        this._project.getResource({id: resourceId, size: 'fullsize'})
          .then((resource) => {
            this._currentSphere.resourceId = resourceId;
            this.updateTexture(type, resource, resourceId);
          }, () => {
            this.$l.warn('SphereBroken didn\'t load');
          });
      }
    });
  }
  setSphere(sphereResourceIds) {
    const canSetSphere = sphereResourceIds && sphereResourceIds.length &&
      (!this._currentSphere.resourceId || sphereResourceIds[0].id !== this._currentSphere.resourceId);

    if (!canSetSphere) { return; }

    const isVideoSphere = sphereResourceIds[0].type === '@ContentVideoSphere';

    // offload previous video sphere
    if (this._sphereMesh.material.map &&
      this._sphereMesh.material.map.image instanceof HTMLVideoElement) {
      const video = this._sphereMesh.material.map.image;
      video.pause();
      offloadVideo(video);
    }

    this._currentSphere.resourceId = sphereResourceIds[0].id;

    this.updateSphere('MESH', this._currentSphere.resourceId, isVideoSphere);

    if (sphereResourceIds.length > 1) {
      this._currentSphere.stereoResourceId = sphereResourceIds[1].id;
      this.updateSphere('STEREOMESH', this._currentSphere.stereoResourceId);

      this._stereoSphereMesh.layers.set(2);
    } else {
      this._currentSphere.stereoResourceId = null;
      this._stereoSphereMesh.layers.set(3);
    }
  }
  remove() {
    this._overlayMesh.material.dispose();
    this._overlayMesh.geometry.dispose();

    this._stereoSphereMesh.material.dispose();
    this._stereoSphereMesh.geometry.dispose();
    this._stereoSphereMesh.texture && this._stereoSphereMesh.texture.dispose();

    if (this._sphereMesh.material.map &&
      this._sphereMesh.material.map.image instanceof HTMLVideoElement) {
      this._sphereMesh.material.map.image.pause();
      this._sphereMesh.material.map.image.src = '';
    }

    this._sphereMesh.material.dispose();
    this._sphereMesh.geometry.dispose();
    this._sphereMesh.texture && this._sphereMesh.texture.dispose();
  }
}

