/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './../Utils/EventsEmitter';
import { Config } from 'evr';
import Utils from 'Utils/Utils';

// reuse geometry for better performance
let planeGeometry;

export default class Label extends EventsEmitter {
  constructor(props) {
    super(props);

    this._disposable = [];
    this.position = props.position;
    this.font = props.font;
    this.size = props.size;
    this.text = props.text;
    this.style = {
      color: props.style.color,
      theme: props.style.theme,
    };

    this.canvas = Utils.$({element: 'canvas'});
    this._texture = new THREE.Texture(this.canvas);
    let material = new THREE.MeshBasicMaterial({
      map: this._texture,
      side: THREE.FrontSide,
      transparent: true,
      depthTest: false,
      depthWrite: false,
      opacity: 1
    });
    if (!planeGeometry) {
      planeGeometry = new THREE.PlaneGeometry(1, 1);
    }
    this.$ = new THREE.Mesh(planeGeometry, material);
    this.$.renderOrder = Config.player.renderOrder.icon;
    this.$.name = 'WidgetLabel';

    this._disposable.push(material);
    this._disposable.push(this._texture);
    this._disposable.push(planeGeometry);

    this.draw();
  }
  draw() {
    function drawBg() {
      var x = 0,
        y = 0,
        width = canvas.width,
        height = canvas.height,
        r = 30,
        radius = {tl: r, tr: r, br: r, bl: r};

      ctx.fillStyle = background;
      ctx.beginPath();
      ctx.moveTo(x + radius.tl, y);
      ctx.lineTo(x + width - radius.tr, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
      ctx.lineTo(x + width, y + height - radius.br);
      ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
      ctx.lineTo(x + radius.bl, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
      ctx.lineTo(x, y + radius.tl);
      ctx.quadraticCurveTo(x, y, x + radius.tl, y);
      ctx.closePath();
      ctx.fill();
    }

    let position = this.position || "bottom",
      font = this.font || "Rubik",
      size = this.size || 8,
      text = this.text || "",
      color = this.style.color,
      background = Config.player.widgets.themes[this.style.theme].background,
      canvas = this.canvas,
      ctx = canvas.getContext('2d'),
      ratio = 10,
      width,
      height,
      positioningStyles = {
        bottom: {sX: 0, sY: -1, tX: 0, tY: -8, alignment: 'center'},
        top: {sX: 0, sY: 1, tX: 0, tY: 8, alignment: 'center'},
        left: {sX: -0.5, sY: 0, tX: -12, tY: 0, alignment: 'right'},
        right: {sX: 0.5, sY: 0, tX: 12, tY: 0, alignment: 'left'},
      },
      labelPositionStyle = positioningStyles[position];

    const margin = {
      top: 0 * ratio,
      right: 4 * ratio,
      left: 4 * ratio,
      bottom: 0 * ratio
    };


    ctx.font = '' + size + 'px ' + font;
    ctx.textAlign = labelPositionStyle.alignment;
    width = ctx.measureText(text).width * ratio + margin.left + margin.right;
    height = (size * 1.40) * ratio + margin.top + margin.bottom;

    canvas.width = width;
    canvas.height = height;
    canvas.style.width = (width / ratio) + 'px';
    canvas.style.height = (height / ratio) + 'px';

    drawBg();

    ctx.font = '' + (ratio * size) + 'px ' + font;
    ctx.fillStyle = color;
    ctx.textBaseline = "middle";
    ctx.fillText(text, margin.left, height / 2);
    ctx.setTransform(ratio, 0, 0, ratio, 0, 0);

    let labelX = labelPositionStyle.tX + labelPositionStyle.sX * (width / ratio),
      labelY = labelPositionStyle.tY + labelPositionStyle.sY * (height / ratio);


    this.$.scale.set(width / ratio, height / ratio, 1);
    this._texture.needsUpdate = true;
    this.$.position.set(labelX, labelY, 0);
  }
  update() {
    this.draw();
  }
  remove() {
    while(this._disposable.length) {
      let el = this._disposable.shift();

      el.dispose();
    }
    this.canvas.width = 0;
    this.canvas.height = 0;
    delete this.canvas;
  }
}

