/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Logger from './Logger';
const _logger = new Logger('Utils');
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';

const Utils = {
  $: ({element = 'div', cssClass, html, id, innerHTML}) => {
    let resultElement = document.createElement(element);

    if (cssClass) {
      resultElement.setAttribute('class', cssClass);
    }

    if (id) {
      resultElement.setAttribute('id', id);
    }

    if (html) {
      resultElement.appendChild(html);
    }

    if (innerHTML) {
      resultElement.innerHTML = innerHTML;
    }

    return resultElement;
  },
  diff: (a, b, depth) => {
    let _diff = (a, b, depth) => {
        return _reduce(a, function(result, value, key) {
          let returnValue;

          if (_isObject(value) && _isObject(a[key])) {
            if (!b[key]) {
              returnValue = [key];
            } else {
              let diffResult = Utils.diff(b[key], value, depth - 1);

              if (diffResult.length) {
                if (depth !== 0) {
                  returnValue = result.concat(_map(diffResult, (a) => { return key + '.' + a; }));
                } else {
                  returnValue = result.concat(key);
                }
              } else {
                returnValue = result;
              }
            }
          } else {
            returnValue = _isEqual(value, b[key]) ?
              result : result.concat(key);
          }

          return returnValue;
        }, []);
      },
      diffA = _diff(a, b, depth),
      diffB = _diff(b, a, depth);

    // add unique values
    for(let i = 0; i < diffB.length; i++) {
      let el = diffB[i];
      if (diffA.indexOf(el) === -1) {
        diffA.push(el);
      }
    }

    return diffA;
  },
  easeInOutCubic: function easeInOutCubic(t) {
    let value = t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    return (value < 0 ? 0 : (value > 1 ? 1 : value));
  },
  extend: function extend() {
    const result = {};

    for (var i = 0; i < arguments.length; i++) {
      for (var prop in arguments[i]) {
        if (arguments[i].hasOwnProperty(prop)) {
          if (!Utils.Types.isObject(arguments[i][prop])) {
            result[prop] = arguments[i][prop];
          } else if (Object.prototype.toString.call(arguments[i][prop]).indexOf('Array') > -1) {
            if (!result[prop]) {
              result[prop] = [];
            }
            for (var j = 0; j < arguments[i][prop].length; j++) {
              let arrEl = arguments[i][prop][j];
              if (!Utils.Types.isObject(arrEl)) {
                result[prop].push(arrEl);
              } else {
                result[prop].push(extend(arrEl));
              }
            }
          } else {
            result[prop] = extend(result[prop], arguments[i][prop]);
          }
        }
      }
    }

    return result;
  },
  findById: function (elements, id) {
    return elements.find(function (element) {
      return element.id === id;
    });
  },

  Math: {
    ceilPow2( value ) {
      var power = Math.log(value) / Math.log(2);
      return Math.max(1,
        Math.min(Math.pow(2, Math.round(power + 0.2)), 4096));
    }
  },
  parseXYZToRadians: function (rotationObj) {
    if (!Utils.Types.isObject(rotationObj)) _logger.error('[Utils]: ' + rotationObj + ' is not an object');
    return new THREE.Vector3(
      (rotationObj.x || 0) * Math.PI / 180,
      (rotationObj.y || 0) * Math.PI / 180,
      (rotationObj.z || 0) * Math.PI / 180
    );
  },
  parseXYZToRadiansEuler: function (rotationObj) {
    if (!Utils.Types.isObject(rotationObj)) _logger.error('[Utils]: ' + rotationObj + ' is not an object');
    return new THREE.Euler(
      (rotationObj.x || 0) * Math.PI / 180,
      (rotationObj.y || 0) * Math.PI / 180,
      (rotationObj.z || 0) * Math.PI / 180
    );
  },
  parseXYZToDegrees: function (rotationObj) {
    if (!Utils.Types.isObject(rotationObj)) _logger.error('[Utils]: ' + rotationObj + ' is not an object');
    return new THREE.Vector3(
      (rotationObj.x || 0) * 180 / Math.PI,
      (rotationObj.y || 0) * 180 / Math.PI,
      (rotationObj.z || 0) * 180 / Math.PI
    );
  },

  search: function (array, compareFunction) {
    for (var i = 0; i < array.length; i++) {
      if (compareFunction(array[i])) {
        return array[i];
      }
    }
  },
  xhr: function(method, url) {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open(method.toUpperCase(), url);
      xhr.onload = function() {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response?JSON.parse(xhr.response):{});
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function() {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.send();
    });
  },
  Browser: {
    mobile: function (a) {
      return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)));
    }(navigator.userAgent || navigator.vendor || window.opera),
    tablet: function (a) {
      return /Tablet|iPad/i.test(a);
    }(navigator.userAgent || navigator.vendor || window.opera),
    opera: (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0,
    firefox: typeof InstallTrigger !== 'undefined',
    safari: function() {
      return Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 || !Utils.Browser.chrome && !Utils.Browser.opera && window.webkitAudioContext !== undefined;
    },
    isIOS: function() {
      return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    },
    ie: false || !!document.documentMode,
    edge: !(false || !!document.documentMode) && !!window.StyleMedia,
    chrome: (!!window.chrome && !!window.chrome.webstore) || /Chrome/.test(navigator.userAgent),
    webviewEvryplace: (/^EvryplaceFlat /.test(navigator.userAgent)),
  },
  Canvas: {
    wrapText: function (options) {
      function drawLine(x, y, width) {
        var startX = x, endX = width;
        if (options.align === 'center') {
          startX = x - width / 2;
          endX = x + width / 2;
        } else if (options.align === 'right') {
          startX = x - width;
          endX = x;
        }

        options.context.beginPath();
        options.context.moveTo(startX, y + options.lineHeight * 1.1);
        options.context.lineTo(endX, y + options.lineHeight * 1.1);
        options.context.stroke();
      }

      var lastAlign = options.context.textAlign,
        lines = options.text.split("\n"),
        textY = options.y || 0;

      if (options.color) {
        options.context.fillStyle = options.color;
      }

      if (options.font) {
        options.context.font = options.font;
      }

      if (options.align) {
        options.context.textAlign = options.align;
      }

      for (var ii = 0; ii < lines.length; ii++) {
        var line = "";
        var words = lines[ii].split(" ");
        var lastWidth = 0;

        for (var n = 0; n < words.length; n++) {
          var testLine = line + (n > 0 ? " " : "") + words[n];
          var testWidth = options.context.measureText(testLine).width;

          if (options.maxWidth && testWidth > options.maxWidth) {
            line = line.trim();
            options.context.fillText(line, options.x, textY);
            if (options.underlined) {
              drawLine(options.x, textY, lastWidth);
            }
            lastWidth = 0;
            line = words[n] + " ";
            textY += options.lineHeight;
          } else {
            lastWidth = testWidth;
            line = testLine;
          }
        }

        options.context.fillText(line, options.x, textY);

        if (options.underlined) {
          drawLine(options.x, textY, testWidth);
        }

        if (textY > options.maxHeight) {
          break;
        }
        textY += options.lineHeight;
      }
      options.context.textAlign = lastAlign;

      return textY - options.y; // Dont count starting y to element height
    },
    roundedPath: (context, x, y, width, height, radius) => {
      context.beginPath();
      context.moveTo(x + radius, y);
      context.lineTo(x + width - radius, y);
      context.quadraticCurveTo(x + width, y, x + width, y + radius);
      context.lineTo(x + width, y + height - radius);
      context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
      context.lineTo(x + radius, y + height);
      context.quadraticCurveTo(x, y + height, x, y + height - radius);
      context.lineTo(x, y + radius);
      context.quadraticCurveTo(x, y, x + radius, y);
      context.closePath();
    }
  },
  Types: {
    isObject: function(obj) {
      return (typeof obj === "object" && obj !== null);
    }
  },
  // This wrapper returns new promise, which will resolve if permission is granted and reject otherwise
  wrapPermissionPromise: function wrapPermissionPromise(promise, logLabel = '') {
    return new Promise((resolve, reject) => {
      promise
        .then(permissionState => {
          console.log(logLabel, permissionState);
          if (permissionState === 'granted') {
            resolve(permissionState);
          } else {
            reject(permissionState);
          }
        })
        .catch(error => {
          console.error(logLabel, 'failed', error);
          reject(error);
        });
    });
  }
};

export default Utils;

