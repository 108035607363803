/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './../Utils/EventsEmitter';
import FontFaceObserver from 'fontfaceobserver';
import { Config } from 'evr'
import Logger from 'Utils/Logger'
import Utils from 'Utils/Utils'
// TODO: Fonts should be loaded as Signed Distance Field fonts

let loader = new THREE.TextureLoader();
loader.crossOrigin = 'anonymous';

export default class ResourceLoader extends EventsEmitter {
  constructor({player, ui}) {
    super();
    this._ui = ui;
    this._player = player;
    this.$l = new Logger('ResourceLoader');
    this.anisotropy = 1;
  }
  setMaxAnisotropy(maxAnisotropy) {
    this.anisotropy = maxAnisotropy;
  }
  getBasePath() {
    const { _config } = this._player;
    return _config.assetUrl || _config.baseUrl;
  }
  getThumbnailPath({hash, pathThumbnail, path}) {
    if (!hash) {
      return pathThumbnail || path;
    }

    return this.getBasePath() + '/api/v1/files/stream/THUMBNAIL/' + hash;
  }
  getImagePath({variants = [], path, hash}) {
    let size;
    
    if (!variants.length) {
      return path;
    }
    
    if (variants.indexOf('STANDARD') !== -1) {
      size = 'STANDARD';
    } else {
      variants.forEach((variant) => {
        if (variant != 'THUMBNAIL') {
          size = variant;
        }
      });
    }
    
    if (!size) return;
    
    return this.getBasePath() + '/api/v1/files/stream/' + size + '/' + hash;
  }
  getFullsizePath({ variants = [], path, hash}) {
    let size;

    if (!variants.length) {
      return path;
    }

    if (variants.indexOf("SPHERE4K") != -1) {
      size = "SPHERE4K";
    } else if (variants.indexOf("SPHERE2K") != -1) {
      size = "SPHERE2K";
    } else if (variants.indexOf("ORIGINAL") != -1) {
      size = "ORIGINAL";
    } else {
      variants.forEach((variant) => {
        if (variant != 'THUMBNAIL') {
          size = variant;
        }
      });
    }

    if (!size) return;
    
    return this.getBasePath() + '/api/v1/files/stream/' + size + '/' + hash;
  }
  getAudioPath(hash) {
    return this.getBasePath() + '/api/v1/files/stream/AUDIO/' + hash;
  }
  loadIcon(resource) {
    let loadPromise = new Promise((resolve, reject) => {
      let img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = this._player._config.baseUrl + Config.context + '/icons/' + resource.path;
      img.addEventListener('load', () => {
        resource.data = img;
        resolve(img);
      });
      img.addEventListener('error', (err) => {
        this.$l.error(img.src);
        this.$l.error(err);
        reject(err);
      });
    });
    resource.loadPromise = loadPromise;
    return loadPromise;
  }
  loadImage({size, resource}) {
    let loadPromise;

    if (size == "thumbnail") {
      resource.pathThumbnail = this.getThumbnailPath(resource);
    } else {
      resource.path = this.getImagePath(resource);
    }


    loadPromise = new Promise((resolve, reject) => {
      let img = new Image(),
        path;
      
      img.crossOrigin = 'anonymous';
      img.addEventListener('load', () => {
        resource.data = img;
        resolve(img);
      });
      img.addEventListener('error', (err) => {
        this.$l.warn('Failed to load image from: ' + img.src);
        reject(err);
      });
      
      if (size == "thumbnail") {
        path = resource.pathThumbnail;
      } else {
        path = resource.path;
      }
      
      if (path) {
        img.src = path;
      } else {
        this.$l.warn('Failed to load image from: ' + path);
        reject();
      }
    });
    resource.loadPromise = loadPromise;
    return loadPromise;
  }

  loadProject(projectHash) {
    let projectPath =  this._player._config.baseUrl + '/api/v1/presentations/' + projectHash;

    return new Promise(function(resolve, reject) {
      Utils.xhr('get', projectPath)
        .then(function(data) {
          if (data.publicContent && data.publicContent.content) {
            resolve(data.publicContent.content);
          } else {
            reject('Invalid server response.');
          }
        }, function (err) {
          reject(err);
        });
    });
  }

  createVideoElement(path) {
    const shouldMuteVideo = Utils.Browser.mobile && Utils.Browser.isIOS();
    const videoElement = document.createElement('video');

    videoElement.setAttribute('webkit-playsinline', 'webkit-playsinline');
    videoElement.setAttribute('playsinline', 'playsinline');
    videoElement.setAttribute('preload', 'none');

    videoElement.loop = false;
    videoElement.crossOrigin = '';

    if (shouldMuteVideo) {
      videoElement.muted = true;
    }

    videoElement.src = path;

    return videoElement;
  }

  loadVideo({ resource }) {
    const { path } = resource;
    const videoPath = path || this.getFullsizePath({
      ...resource, variants: ['VIDEO']
    });
    const videoElement = this.createVideoElement(videoPath);
    const loadPromise = Promise.resolve(videoElement);

    Object.assign(resource, {
      path: videoPath,
      thumbnail: { loadPromise }
    });

    return loadPromise;
  }

  loadVideoSphere({ resource }) {
    const { path } = resource;
    const videoPath = path || this.getFullsizePath({
      ...resource, variants: ['VIDEO']
    });
    const videoElement = this.createVideoElement(videoPath);
    const videoTexture = new THREE.VideoTexture(videoElement);
    const loadPromise = Promise.resolve(videoTexture);

    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;
    videoTexture.format = THREE.RGBFormat;

    Object.assign(resource, {
      path: videoPath,
      thumbnail: { loadPromise }
    });

    return loadPromise;
  }

  loadSphere({ resource, size }) {
    let loadPromise;

    if (size == 'thumbnail') {
      resource.pathThumbnail = this.getThumbnailPath(resource);
    } else {
      resource.path = this.getFullsizePath(resource);
    }

    resource[size] = {};

    loadPromise = new Promise((resolve, reject) => {
      if (!resource) reject('No data.');

      const loadPath =
        size === 'thumbnail' ? resource.pathThumbnail : resource.path;

      const onSuccess = (texture) => {
        texture.anisotropy = this.anisotropy;
        resource[size].data = texture;
        resolve(texture);
      };

      const onProgress = () => {};

      const onError = (error) => {
        const hasSource =
          error && error.path && error.path.length && error.path[0].currentSrc;

        const warning = hasSource ?
          'Could not load: ' + error.path[0].currentSrc :
          error;

        this.$l.warn(warning);

        reject(error);
      };

      if (loadPath) {
        loader.load(loadPath, onSuccess, onProgress, onError);
      } else {
        onError("Could not load sphere resource with id: " + resource.id);
      }
    });

    resource[size].loadPromise = loadPromise;

    return loadPromise;
  }
  load(newResources, loadFontsEnabled) {
    let progress = -1,
      promises = [],
      resourcesLength = Object.keys(newResources).length,
      progressChanged = () => {
        progress++;

        let percentage = parseInt(progress * 100 / resourcesLength);
        percentage = Math.max(0, Math.min(100, percentage));
        this.emit('load', percentage);
      },
      loadFonts = (newFonts) => {
        let customText = "AĄaą",
          promises = [],
          watchFont = (font, customText) => {
            let fontFamily = font.name,
              weightPattern = /(\d+)(i?)/;

            if (font.types && font.types.length) {
              for (let j = 0; j < font.types.length; j++) {
                let weigth;
                if ((weigth = weightPattern.exec(font.types[j])) !== null) {
                  var options = {};
                  options.weight = +(weigth[1]);
                  if (weigth[2] === 'i') {
                    options.style = 'italic';
                  }
                  return new FontFaceObserver(fontFamily, options).load(customText);
                }
              }
            } else {
              this.$l.error('Couldn\'t load font: ' + font.name);
            }
          },
          createElements = (font, customText) => {
            let link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = 'https://fonts.googleapis.com/css?family=' + font.name;
            if (font.types && font.types.length) {
              link.href += ':' + font.types.join(',');
            }
            document.head.appendChild(link);

            let testSpan = document.createElement('span');
            testSpan.style.fontSize = '70px';
            testSpan.style.fontFamily = font.name;
            testSpan.className = font.name;
            testSpan.innerHTML = customText;
            this._ui.$fontsContainer.appendChild(testSpan);
          };

        for(let fontId in newFonts) {
          let font = newFonts[fontId];

          if (!this._ui.$fontsContainer.querySelector('.' + font.name)) {
            createElements(font, customText);

            let fontPromise = new Promise((resolve) => {
              watchFont(font, customText).then(resolve, resolve);
            });

            promises.push(fontPromise);
          }
        }

        // iOS hack - fontfaceobserver doesn't work properly
        if (Utils.Browser.isIOS()) {
          promises.push(new Promise((resolve) => {setTimeout(()=>resolve(), 1500)}));
        }

        return promises;
      },
      resultResources = Utils.extend({}, newResources);

    progressChanged();

    return new Promise((resolve, reject) => {
      let fontsResources;

      for(let resourceId in resultResources) {
        let resource = resultResources[resourceId],
          loadPromise;

        if(resource){
          let type = resource.type;

          resource.id = resourceId;

          if (type === '@ContentIcon') {
            loadPromise = this.loadIcon(resource).then(progressChanged, progressChanged);
          } else if (type === '@ContentSphere') {
            loadPromise = this.loadSphere({ resource, size: 'thumbnail' }).then(progressChanged, progressChanged);
          } else if (type === '@ContentVideoSphere') {
            loadPromise = this.loadVideoSphere({ resource }).then(progressChanged, progressChanged);
          } else if (type === '@ContentImage') {
            loadPromise = this.loadImage({ resource, size: 'fullsize' }).then(progressChanged, progressChanged);
          } else if (type === '@Audio') {
            // Don't preload audio, but fill 'data' if only hash is provided
            if (!resource.data && resource.hash) {
              resource.data = this.getAudioPath(resource.hash);
            }
          } else if (type === '@ContentVideo') {
            loadPromise = this.loadVideo({ resource }).then(progressChanged, progressChanged);
          } else if (type !== '@Font') {
            this.$l.error('Invalid resource type. Resource id: ' + resource.id);
          }
        } else {
          loadPromise = Promise.resolve();
        }

        promises.push(loadPromise);
      }

      if (loadFontsEnabled !== false) {
        fontsResources = Object.keys(resultResources)
          .filter(key => {
            let resource = resultResources[key];
            return resource && resource.type === '@Font';
          })
          .reduce((obj, key) => {
            obj[key] = resultResources[key];
            return obj;
          }, {});

        if (Object.keys(fontsResources).length > 0) {
          promises = promises.concat(loadFonts(fontsResources));
        }
      }

      Promise.all(promises).then(() => {
        resolve(resultResources);
      }, (err) => {
        this.$l.warn(err);
        resolve(resultResources);
      });
    });
  }
  loadResource({scope, resource}) {
    switch(scope) {
      case 'spheres':
        return this.loadSphere({ resource: resource, size: "fullsize"});
      case 'images':
        return this.loadImage({ resource: resource });
      case 'videos':
        return this.loadVideo({ resource: resource });
    }
  }
  loadFullsizeSphere(options) {
    if (!options.id) return;

    return this.loadSphere({id: options.id, size: 'fullsize', resource: options.resource});
  }
}

