/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './../Utils/EventsEmitter';
import { Controller } from './Controller';

/**
 * Enum for valid viewing tools.
 *
 * @readonly
 * @enum {string}
 */
export const Tools = {
  /** Pan tool */
  pan: 'pan',
  /** Draw tool */
  draw: 'draw',
};

/**
 * Class representing a viewing host controller.
 *
 * @memberof  Viewing
 *
 * @example
 * //Prepare player
 * this.player.instance.load(projectData);
 * var hostController = new evr.Viewing.Host({
 *  player: playerInstance,
 *  audioElement: audioElementInstance,
 *  toolSelected: Evr.Viewing.Tools.pan,
 *  presentingMode: true,
 * });
 * hostController.setConfig({
 *   socketHost: 'https://webvrtest.fream.pl:3001',
 * });
 * hostController.on('hostConnected', function() {
 *  console.log('host connected');
 * });
 * hostController.connect({
 *   hash: '123abc',
 * });
 *
 * @extends EventsEmitter
 * @emits Viewing.Host#hostConnected
 * @emits Viewing.Host#clientConnected
 * @emits Viewing.Host#hostDisconnected
 * @emits Viewing.Host#clientDisconnected
 * @emits Viewing.Host#audioConnected
 * @emits Viewing.Host#audioDisconnected
 * @emits Viewing.Host#clientName
 * @emits Viewing.Host#setWatching
 */
class Host extends EventsEmitter {
  /**
   * Create viewing host controller instance
   *
   * @param {Object} options
   * @param {Player} options.player - player instance
   * @param {boolean} options.presentingMode - if set to true, controller will stream data from events: cameraViewportChanged, closeWidget, openWidget, changeScene
   * @param {Tools} options.toolSelected - valid tool name from {Tools} class.
   */
  constructor({player, presentingMode = false, toolSelected = Tools.pan, debug = false }) {
    super();

    this.config = null;
    this.player = player;
    this.controller = null;
    this.presentingMode = presentingMode;
    this.peer = null;
    this.remoteStream = null;
    this.localMedia = null;
    this.micMuted = false;
    this.speakerMuted = false;
    this.toolSelected = toolSelected;
    this.$l = new evr.Logger('Viewing.Host');
    this._mode = null;
    this._modeOptions = {};
    this.debug = debug;

    this._checkDisconnection = this._checkDisconnection.bind(this);
    this._onPlayerClick = this._onPlayerClick.bind(this);
    this._onPlayerPointerMove = this._onPlayerPointerMove.bind(this);
    this._onPlayerPointerLeave = this._onPlayerPointerLeave.bind(this);
    this._onPlayerMouseUp = this._onPlayerMouseUp.bind(this);
    this._onPlayerChangeScene = this._onPlayerChangeScene.bind(this);
    this._onPlayerOpenWidget = this._onPlayerOpenWidget.bind(this);
    this._onPlayerCloseWidget = this._onPlayerCloseWidget.bind(this);
    this._onPlayerCameraViewportChanged = this._onPlayerCameraViewportChanged.bind(this);
    this._onPlayerOpenLinkStart = this._onPlayerOpenLinkStart.bind(this);
    this._onPlayerOpenLinkEnd = this._onPlayerOpenLinkEnd.bind(this);
    this._onControllerConnection = this._onControllerConnection.bind(this);
    this._onControllerConnected = this._onControllerConnected.bind(this);
    this._onControllerDisconnection = this._onControllerDisconnection.bind(this);
    this._onControllerDisconnected = this._onControllerDisconnected.bind(this);
    this._onControllerData = this._onControllerData.bind(this);
    this._onControllerPeerUpgraded = this._onControllerPeerUpgraded.bind(this);
    this._onControllerStreamAdded = this._onControllerStreamAdded.bind(this);

    if (this.debug) {
      const hostEvents = [
        'audioConnected', 'audioDisconnected', 'hostConnected', 'hostDisconnected',
        'micAccessFailed', 'modeChanged', 'peerMicMuted', 'peerUpgraded', 'peerDowngraded',
        'peerDisconnected', 'clientConnected', 'clientDisconnected', 'clientName'
      ];

      hostEvents.forEach(event => {
        this.on(event, (data) => { console.log('[Host]:', event, data);});
      });
    }
  }
  /**
   * Starts viewing with current player and config.
   *
   * @param {Object} options
   * @param {String} options.hash - stream id
   */
  connect({ hash }) {
    const config = this.config,
      player = this.player;

    if (this.controller) {
      this.$l.error('Viewing already connected');
      return false;
    }

    if (!config) {
      this.$l.error("config is undefined. Use setConfig() first.");
      return;
    }
    if (!hash) {
      this.$l.error("Undefined viewing hash");
      return;
    }
    if (!config.socketHost) {
      this.$l.error("config.socketHost is undefined. Use setConfig() first.");
      return;
    }
    if (!player) {
      this.$l.error("config.socketHost is undefined. Use setConfig() first.");
      return;
    }

    this.controller = new Controller({
      socket: {
        server: config.socketHost
      },
      hash: hash,
      role: 'host'
    });

    this.player._ui.$.appendChild(this.controller.$audio);

    this._mode = this.presentingMode ? this.controller.modes.guide : this.controller.modes.follow;

    this.player.on('click', this._onPlayerClick);
    this.player.on('pointerMove', this._onPlayerPointerMove);
    this.player.on('pointerLeave', this._onPlayerPointerLeave);
    this.player.on('mouseUp', this._onPlayerMouseUp);
    this.player.on('changeScene', this._onPlayerChangeScene);
    this.player.on('openWidget', this._onPlayerOpenWidget);
    this.player.on('closeWidget', this._onPlayerCloseWidget);
    this.player.on('cameraViewportChanged', this._onPlayerCameraViewportChanged);
    this.player.on('openLinkStart', this._onPlayerOpenLinkStart);
    this.player.on('openLinkEnd', this._onPlayerOpenLinkEnd);
    this.controller.on('connection', this._onControllerConnection);
    this.controller.on('connected', this._onControllerConnected);
    this.controller.on('disconnection', this._onControllerDisconnection);
    this.controller.on('disconnected', this._onControllerDisconnected);
    this.controller.on('data', this._onControllerData);
    this.controller.on('peerUpgraded', this._onControllerPeerUpgraded);
    this.controller.on('peerDisconnected', this._checkDisconnection);
    this.controller.on('peerDowngraded', this._checkDisconnection);
    this.controller.on('streamAdded', this._onControllerStreamAdded);
    this.controller.connect();

    this._onOnline = () => {
      if (this.debug) {
        console.log("online");
      }
      window.removeEventListener('online', this._onOnline);
      this.connect({ hash });
    };

    this._onOffline = () => {
      if (this.debug) {
        console.log("offline");
      }
      this.disconnect();
      window.addEventListener('online', this._onOnline);
    };

    window.addEventListener('offline', this._onOffline);
  }
  /**
   * Disconnect websocket connection
   */
  disconnect() {
    this.clearDrawing();
    this.disconnectAudio();
    this.controller.disconnect();
    this.player.off('click', this._onPlayerClick);
    this.player.off('pointerMove', this._onPlayerPointerMove);
    this.player.off('pointerLeave', this._onPlayerPointerLeave);
    this.player.off('mouseUp', this._onPlayerMouseUp);
    this.player.off('changeScene', this._onPlayerChangeScene);
    this.player.off('openWidget', this._onPlayerOpenWidget);
    this.player.off('closeWidget', this._onPlayerCloseWidget);
    this.player.off('cameraViewportChanged', this._onPlayerCameraViewportChanged);
    this.player.off('openLinkStart', this._onPlayerOpenLinkStart);
    this.player.off('openLinkEnd', this._onPlayerOpenLinkEnd);
    this.controller.off('connection', this._onControllerConnection);
    this.controller.off('connected', this._onControllerConnected);
    this.controller.off('disconnection', this._onControllerDisconnection);
    this.controller.off('disconnected', this._onControllerDisconnected);
    this.controller.off('data', this._onControllerData);
    this.controller.off('peerUpgraded', this._onControllerPeerUpgraded);
    this.controller.off('peerDisconnected', this._checkDisconnection);
    this.controller.off('peerDowngraded', this._checkDisconnection);
    this.controller.off('streamAdded', this._onControllerStreamAdded);
    this.controller = null;
  }
  /**
   * Connect audio stream
   *
   * @emits 'audioConnected'
   */
  connectAudio() {
    this.peer.connection.localMedia.start({audio: true})
      .then((media) =>{
        this.localMedia = media;
        this.emit('audioConnected', {});
      }, err => {
        this.emit('micAccessFailed', err);
      });
  }
  /**
   * Disconnect audio stream
   *
   * @emits 'audioDisconnected'
   */
  disconnectAudio() {
    if(this.remoteStream){
      this.remoteStream.getTracks().forEach(track => {
        track.stop();
      });
    }
    this.remoteStream = null;
    if(this.localMedia){
      this.localMedia.stop();
      this.emit('audioDisconnected', {});
    }
    this.localMedia = null;
  }
  /**
   *  Apply volume to audio stream
   *
   * @param {Number} volume - From 0.0 to 1.0
   */
  applyVolume(volume) {
    if (this.controller.$audio) {
      this.controller.$audio.volume = volume;
    }
  }
  /**
   * Sets controller tool
   *
   * @param {String} tool - valid tool name from {Tools} class.
   */
  setTool(tool) {
    if (Tools[tool]) {
      this.toolSelected = tool;

      if (this.player) {
        tool === Tools.draw && this.player._stage._controls.disableControls();
        tool === Tools.pan && this.player._stage._controls.enableControls();
      }
    }
  }
  /**
   * Clear all drawnings
   */
  clearDrawing() {
    this.player.clearAllLines();
    this.controller.send({
      c: [{t: this.controller.commands.clearAllLines}]
    });
  }
  /**
   * Toggle microphone
   */
  toggleMic() {
    if (this.localMedia) {
      this.micMuted = !this.micMuted;
      this.localMedia.enable('audio', !this.micMuted);
      this.controller.send({c: [{t: this.controller.commands.showMicMuted, d: this.micMuted}]});
    }
  }
  /**
   * Toggle speakers
   */
  toggleSpeaker() {
    if (this.controller.$audio) {
      this.speakerMuted = !this.speakerMuted;
      this.controller.$audio.muted = this.speakerMuted;
    }
  }
  /**
   *  Sets presenting mode
   *
   * @param {boolean} presenting - if set to true, controller will stream data from events: cameraViewportChanged, closeWidget, openWidget, changeScene
   * @param {boolean} mirror - if set to true, there will be no fov frame, hosts camera will follow clients
   */
  setMode({ presenting = true, mirror }) {
    this._mode = presenting ? this.controller.modes.guide : this.controller.modes.follow;
    this._modeOptions = mirror === undefined ? {} : { mirror };
    if (this._mode === this.controller.modes.guide) {
      this.player.enableWidgets();
    }
    if (this._mode === this.controller.modes.follow) {
      this.player.disableWidgets();
      if (this._modeOptions.mirror) {
        this.player.hideCameraViewport();
      }
    }
    this.controller.send({ c: [{ t: this.controller.commands.changeMode, d: this._mode }] });
    this.emit('modeChanged', this._mode);
  }
  /**
   * Set client options
   *
   * @param {Object} options
   * @param {String} options.socketHost - socket host
   */
  setConfig(options) {
    this.config = options;
  }
  _checkDisconnection(peer) {
    if (this.peer === peer) {
      this.emit('peerDowngraded', peer);
      this.disconnectAudio();
      this.peer = null;
    }
  }
  _onPlayerClick(e) {
    if (!e.target) {
      this.player.addPointer(e.rotation);
      this.controller.send({c:[{t:this.controller.commands.showPlumker, d: e.rotation}]});
    }
  }
  _onPlayerPointerMove(e) {
    this.player.moveLaserPointer(e.dirPosition);

    var packedPosition = [
      this.controller.packNormFloat(e.dirPosition.x),
      this.controller.packNormFloat(e.dirPosition.y),
      this.controller.packNormFloat(e.dirPosition.z),
    ].join(';');

    var commands = [{t: this.controller.commands.pointerPosition, d: packedPosition}];
    if (this.toolSelected === Tools.draw && e.isMouseDown) {
      this.player.addLineSegment(e.dirPosition);
      commands.push({t: this.controller.commands.addLineSegment, d: packedPosition});
    }
    this.controller.send({ c: commands });
  }
  _onPlayerPointerLeave() {
    this.player.closeLine();
    this.player.closeLaserPointer();
    this.controller.send({
      c: [{t: this.controller.commands.closeLine},
        {t: this.controller.commands.closePointer}]
    });
  }
  _onPlayerMouseUp() {
    this.player.closeLine();
    this.controller.send({
      c: [{t: this.controller.commands.closeLine}]
    });
  }
  _onPlayerChangeScene(data) {
    if (this._mode === this.controller.modes.guide) {
      this.controller.send({c:[{t:this.controller.commands.changeScene, d: data}]});
    }
    this.clearDrawing();
  }
  _onPlayerOpenWidget(e) {
    if (this._mode === this.controller.modes.guide) {
      this.controller.send({c:[{t:this.controller.commands.openWidget, d: e.widgetId}]});
    }
  }
  _onPlayerCloseWidget(e) {
    if (this._mode === this.controller.modes.guide) {
      this.controller.send({c:[{t:this.controller.commands.closeWidget, d: e.widgetId}]});
    }
  }
  _onPlayerCameraViewportChanged(e) {
    if (this._mode === this.controller.modes.guide) {
      this.controller.send({c: [{t: this.controller.commands.cameraViewportChanged, d: this.controller.packViewportData(e)}]});
    }
  }
  _onPlayerOpenLinkStart() {
    if (this._mode === this.controller.modes.guide) {
      this.controller.send({c:[{t:this.controller.commands.openLinkStart}]});
    }
  }
  _onPlayerOpenLinkEnd() {
    if (this._mode === this.controller.modes.guide) {
      this.controller.send({c:[{t:this.controller.commands.openLinkEnd}]});
    }
  }
  _onControllerConnection(e) {
    this.emit('hostConnected', e);
  }
  _onControllerConnected(e) {
    const commands = [];
    if (this.player._stage._state.sceneId) {
      const sceneId = this.player._stage._state.sceneId;
      const intiaialSceneId = this.player._project._data.initialState.sceneId;
      // host changed scene before client connected
      if (sceneId !== intiaialSceneId) {
        // notify client to change scene
        commands.push({ t: this.controller.commands.changeScene, d: { sceneId } });
      }
    }
    const drawings = this.player.getDrawings();
    if (drawings && drawings.length) {
      // show host current drawing on client
      commands.push({ t: this.controller.commands.setDrawings, d: drawings });
    }
    this.emit('clientConnected', e);
    commands.push({ t: this.controller.commands.changeMode, d: this._mode });

    this.controller.send({ c: commands });
  }
  _onControllerDisconnection(e) {
    this.player.hideCameraViewport();
    this.emit('hostDisconnected', e);
    this.setTool(Tools.pan);
    this.setMode({ presenting: true });
  }
  _onControllerDisconnected(e) {
    this.player.hideCameraViewport();
    // switch to guide mode when client disconnected
    this.setMode({ presenting: true });
    this.emit('clientDisconnected', e);
  }
  _onControllerData(payload) {
    let data = payload.data,
      commands = this.controller.commands;

    if (data.name) {
      this.emit('clientName', data.name);
    }

    if (data && data.c && Array.isArray(data.c)) {
      for (let i = 0; i < data.c.length; i++) {
        let command = data.c[i],
          type = command.t;

        if(type === commands.rotateCamera) {
          this.player.rotateCamera(command.data);
        }
        else if(type === commands.changeScene) {
          this.player.changeScene(command.d);
        }
        else if(type === commands.openWidget) {
          this.player.openWidget({ widgetId: command.d, animating: true });
        }
        else if(type === commands.closeWidget) {
          this.player.closeWidget({widgetId: command.d, animating: true });
        }
        else if(type === commands.showMicMuted){
          this.emit('peerMicMuted', command.d);
        }
        else if (type === commands.cameraViewportChanged) {
          var fields = ['lt', 'lb', 'rt', 'rb', 'c'];
          var valueKeys = ['x', 'y', 'z'];
          var arr = command.d.split(';');
          var obj = {};
          for (let j = 0; j < fields.length; j++) {
            obj[fields[j]] = {};
            for (let k = 0; k < valueKeys.length; k++) {
              var mappedValue = this.controller.unpackNormFloat(arr[j * valueKeys.length + k]);
              obj[fields[j]][valueKeys[k]] = mappedValue;
            }
          }
          if (this._mode === this.controller.modes.follow && this._modeOptions.mirror) {
            var cameraDirection = obj.c;
            var angleY = Math.atan2(-cameraDirection.x, -cameraDirection.z);
            var cameraRotation = new THREE.Vector3(-Math.asin(cameraDirection.y) * 180.0 / Math.PI, angleY * 180.0 / Math.PI, 0);
            this.player.rotateCamera({ rotation: cameraRotation });
          } else {
            this.player.showCameraViewport(obj);
          }
        }
        else if (type === commands.setWatching) {
          this.emit('setWatching', command.d);
        }
      }
    }
  }
  _onControllerPeerUpgraded(peer) {
    this.peer = peer;
    this.emit('peerUpgraded', peer);
  }
  _onControllerStreamAdded(connection) {
    this.remoteStream = connection.stream;
    this.controller.$audio.srcObject = connection.stream;
  }
}

/**
 * Host connects to socket server
 *
 * @event Viewing.Host#hostConnected
 * @type {object}
 */

/**
 * Client connects to socket server
 *
 * @event Viewing.Host#clientConnected
 * @type {object}
 */

/**
 * Host disconnects from socket server
 *
 * @event Viewing.Host#hostDisconnected
 * @type {object}
 */

/**
 * Client disconnects from socket server
 *
 * @event Viewing.Host#clientDisconnected
 * @type {object}
 */

/**
 * Client and host connected audio stream
 *
 * @event Viewing.Host#audioConnected
 * @type {object}
 */

/**
 * Client and host disconnected audio stream
 *
 * @event Viewing.Host#audioDisconnected
 * @type {object}
 */

/**
 * Client set his name
 *
 * @event Viewing.Host#clientName
 * @type {string}
 */

/**
 * Client sends watching value
 *
 * @event Viewing.Host#setWatching
 * @type {boolean}
 */

export default Host;

