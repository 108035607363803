/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from 'Utils/EventsEmitter';
import Sphere from './Sphere';
import Controls from './Controls/';
import WidgetsManager from './Widgets/WidgetsManager';
import PointerManager from 'Utils/PointerManager';
import Animation, { Easings } from 'Utils/Animation';
import "three/examples/js/effects/VREffect";
import WebVRPolyfill from 'webvr-polyfill';
const polyfill = new WebVRPolyfill();
import { WebVRManager, State } from 'webvr-ui/build/webvr-ui';
import { Config } from 'evr';
import Utils from 'Utils/Utils';
import Logger from 'Utils/Logger';
import Speaker from 'Utils/Speaker';

export default class Stage extends EventsEmitter {
  constructor({
    ui,
    player,
    project,
    stats,
    rotateScene,
    editor,
    scaleFactor,
    forceDesktopControls = false,
    enableGyroscope,
    linksEnabled,
    audioEnabled,
    videoSphericalEnabled,
  }) {
    super();

    this._player = player;
    this._editor = editor;
    this._project = project;
    this._ui = ui;
    this._stats = stats;
    this._state = {
      sceneId: null,
      vrEnabled: false,
      vrState: '',
      animating: false,
      autoplay: false,
      rotateScene: rotateScene,
      widgetsEnabled: true,
    };
    this.audioEnabled = audioEnabled;
    this.videoSphericalEnabled = videoSphericalEnabled;

    this._animations = new Animation();
    this.$l = new Logger('Stage');
    this._backgroundAudio; // holds scene background Audio object

    try {
      this._speaker = new Speaker();
    } catch (e) {
      this.$l.error(e);
    }

    this._scaleFactor = scaleFactor;

    this._mousePosition = null;
    this._raycaster = new THREE.Raycaster();
    this._renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
    this._renderer.setClearColor('#fff');
    this._renderer.setPixelRatio(window.devicePixelRatio);
    this._renderer.sortObjects = true;
    ui.$.appendChild(this._renderer.domElement);
    this._camera = new THREE.PerspectiveCamera(75, 1/ 2, 0.1, 10000);
    this._scene = new THREE.Scene();
    this._project.setMaxAnisotropy(this._renderer.getMaxAnisotropy());
    this._webvrManager = new WebVRManager();
    this.initPromise = new Promise((resolve) => {
      this.once('changeMode', () => {
        if (this._state.vrEnabled) {
          this._state.rotateScene = false;
          this._controls.init({vrEnabled: true});
        } else {
          this._controls.init({vrEnabled: false});
        }
        resolve();
      });
    });

    this._crosshair = new THREE.Mesh(
      new THREE.CircleGeometry(0.15, 32),
      new THREE.MeshBasicMaterial({
        color: Config.player.primaryColor,
        transparent: true,
        opacity: 1,
        depthWrite: false,
        depthTest: false,
      })
    );
    this._crosshair.name = "crosshair";
    this._crosshair.position.set(0, 0, - Config.player.crosshairRadius);
    this._crosshair.lookAt(new THREE.Vector3(0, 0, 0));
    this._crosshair.renderOrder = Config.player.renderOrder.crosshair;
    this._crosshair.visible = editor;
    this._camera.add(this._crosshair);

    // Load animation display
    this._webvrManager.checkDisplays()
      .then((display) => {
        if(!display) {
          this._animationDisplay = window;
        } else {
          this._animationDisplay = display;
        }
      })
      .catch((e) => {
        this._animationDisplay = window;
      });

    this._webvrManager.addListener('change', this.onVRModeChange.bind(this));

    // Create VR Effect rendering in stereoscopic mode
    this._effect = new THREE.VREffect(this._renderer);
    this.onResize();

    this._controls = new Controls({
      elementContainer: this._renderer.domElement,
      raycaster: this._raycaster,
      camera: this._camera,
      forceDesktopControls: forceDesktopControls,
      enableGyroscope: enableGyroscope,
      editor: editor
    });
    this._scene.add(this._controls.$);

    // Hande canvas resizing
    this.onResize = this.onResize.bind(this);

    window.addEventListener('resize', this.onResize, true);
    window.addEventListener('vrdisplaypresentchange', this.onResize, true);

    // Request animation frame loop function
    this._lastRender = 0;

    // Set up 3d objects
    this._sphere = new Sphere({
      project: this._project,
      videoSphericalEnabled,
    });
    this._scene.add(this._sphere.$);

    this._widgetsManager = new WidgetsManager({
      stage: this,
      scene: this._scene,
      project: this._project,
      editor: editor,
      speaker: this._speaker,
      animations: this._animations,
      linksEnabled,
      audioEnabled: this.audioEnabled,
    });

    this._widgetsManager.on('openWidget', (callbackData) => {
      if (!callbackData.widgetId) {
        this.$l.error('Invalid widget open event');
        return;
      }

      if (callbackData.action) {
        if (callbackData.action === 'openProject') {
          this._project.loadProject(callbackData.options.projectHash);
        }
        if (callbackData.action === 'changeScene') {
          if (callbackData.options.sceneId ) {
            if (callbackData.options.sceneId === this._state.sceneId && callbackData.options.rotation) {
              this.rotateCamera({animate: true, rotation: callbackData.options.rotation});
            } else {
              this.changeScene(callbackData.options);
            }
          }
        }
      }
      this.emit('openWidget', {widgetId: callbackData.widgetId});
    });

    this._widgetsManager.on('closeWidget', (e) => {
      this.emit('closeWidget', e);
    });

    this._widgetsManager.on('openLink', e => {
      this.emit('openLink', e);
    });

    this._widgetsManager.on('openLinkStart', e => {
      this.emit('openLinkStart', e);
    });

    this._widgetsManager.on('openLinkEnd', e => {
      this.emit('openLinkEnd', e);
    });

    this._widgetsManager.on('changePresentation', e => {
      this.emit('changePresentation', e);
    });

    this._widgetsManager.on('toggleNearestWidget', e => {
      this.emit('toggleNearestWidget', e);
    });

    this._pointerManager = new PointerManager({scene: this._scene});

    this._draggedWidgets = [];
    // Handle controls events

    this._controls.on('click', (coords) => {
      let clickResponse,
        targets = [],
        dir,
        rotation;

      if (this._state.vrState !== 'presenting' && !editor && this._state.widgetsEnabled) {
        this._raycaster.setFromCamera(coords, this._camera);

        dir = this._raycaster.ray.direction;
        rotation = new THREE.Vector3(-Math.asin(dir.y),
          Math.atan2(-dir.x, -dir.z),
          0);

        let openedWidgets = [];

        for(let widgetId in this._widgetsManager._widgets) {
          let widget = this._widgetsManager._widgets[widgetId];
          if (widget.opened || widget.opening) {
            openedWidgets.push(widget.id);
          }
        }

        clickResponse = this._widgetsManager.handleClick({raycaster: this._raycaster});
        targets = clickResponse.targets;
        let widget = targets[0];

        targets = targets.length ? [targets[0]._data.id] : [];
        this.emit('click', {
          position: coords,
          rotation: rotation,
          targets: targets,
          effective: clickResponse.effective,
          widget: widget
        });
      }
    });

    this._controls.on('mouseDown', (coords) => {
      this._animations.stop('rotateCamera');
      this._state.rotateScene = false;
      this.stopAutoplay();

      if (editor) {
        let targets = [],
          dir,
          rotation;

        this._draggedWidgets = [];
        this._raycaster.setFromCamera(coords, this._camera);

        dir = this._raycaster.ray.direction.clone();
        rotation = new THREE.Vector3(-Math.asin(dir.y),
          Math.atan2(-dir.x, -dir.z),
          0);

        targets = this._widgetsManager.intersectWidgets({raycaster: this._raycaster});

        // if clicked at least one widget, drag it
        if (targets[0]) {
          if (targets[0].isHighlighted()) {
            let subRotation = rotation.clone().sub(targets[0]._data.rotation);
            this._draggedWidgets.push({widget: targets[0], subRotation: subRotation});
          } else {
            this._widgetsManager.highlightWidgets([targets[0].id]);
            targets[0].setHighlight(true);
          }
        }

        if (this._draggedWidgets.length) this._controls.disableControls();

        // return only id of first item
        targets = targets.length?[targets[0]._data.id]:[];

        this.emit('click', {position: coords, rotation: rotation, targets: targets});
      }
    });

    this._controls.on('mouseUp', () => {
      if (editor) {
        this._draggedWidgets = [];
        this._controls.enableControls();
      }
      this.emit('mouseUp', {});
    });

    this._controls.on('mouseMove', (coords) => {
      this._raycaster.setFromCamera(coords, this._camera);

      let dir = this._raycaster.ray.direction,
        position = (this._camera.position.clone()).add(dir.clone().multiplyScalar(Config.player.sphereRadius)),
        angleY = Math.atan2(-position.x, -position.z);

      if (editor) {
        if (this._draggedWidgets.length) {
          let widget = this._draggedWidgets[0].widget;


          let sub = this._draggedWidgets[0].subRotation;

          widget._data.rotation = new THREE.Vector3(-Math.asin(dir.y)-sub.x,
            Math.atan2(-dir.x, -dir.z)-sub.y,
            0);

          if (widget._data.rotation.x < -Math.PI/2) {
            widget._data.rotation.x = -Math.PI/2;
          }

          if (widget._data.rotation.x > Math.PI/2) {
            widget._data.rotation.x = Math.PI/2;
          }

          if (widget._data.rotation.y < -Math.PI) {
            widget._data.rotation.y += Math.PI*2;
          }

          if (widget._data.rotation.y > Math.PI) {
            widget._data.rotation.y -= Math.PI*2;
          }

          widget.updateRotationPosition();

          this.emit('widgetPositionChanged', widget._data);
        }
      } else {
        this._widgetsManager.handleMove(position);
      }

      position.normalize();
      this._scene.worldToLocal(position);

      this.emit('mouseMove', {dirPosition: position.clone(), position: Utils.parseXYZToDegrees(new THREE.Vector3(-Math.asin(position.y), angleY, 0))});
    });

    this._controls.on('drag', (coords) => {
      this.emit('drag');
    });

    // Turn off double tap for webview
    if (!Utils.Browser.webviewEvryplace) {
      this._controls.on('doubleClick', (options) => {
        this.rotateCamera({rotation: options.rotation, animate: true});
      });
    }

    this._controls.on('mouseLeave', () => {
      this.emit('mouseLeave', {});
    });

    this._controls.on('drop', e => {
      this._raycaster.setFromCamera(e.coords, this._camera);

      let dir = this._raycaster.ray.direction;
      e.rotation = new THREE.Vector3(-Math.asin(dir.y), Math.atan2(-dir.x, -dir.z), 0);

      this.emit('drop', e);
    });
  }
  onVRModeChange(newState) {
    if (newState != this._state.vrState) {
      this._state.vrState = newState;

      this._crosshair.visible = this._editor ;

      switch (newState) {
      case State.READY_TO_PRESENT:
        this._state.vrEnabled = true;
        break;
      case State.PRESENTING:
        this.stopAutoplay();
        this._widgetsManager.removeLinks();
        this._crosshair.visible = true;
        break;
      case State.PRESENTING_FULLSCREEN:
        break;
        // Error states
      case State.ERROR_BROWSER_NOT_SUPPORTED:
      case State.ERROR_NO_PRESENTABLE_DISPLAYS:
      case State.ERROR_REQUEST_TO_PRESENT_REJECTED:
      case State.ERROR_EXIT_PRESENT_REJECTED:
        break;
        // default:
      }

      this.emit('changeMode', newState);
    }
  }
  changeScene(options) {
    this._animations.stop('rotateCamera');
    let newScene = this._project.getScene({sceneId: options.sceneId});

    return new Promise((resolve, reject) => {
      if (!newScene) {
        if (!this._state.sceneId) {
          this._sphere.setSphere([{id: 'spherePlaceholder'}]);
        }

        this.$l.warn('There\'s no scene with id: ' + options.sceneId);
        reject();
        return;
      }

      if (this._backgroundAudio) {
        this._backgroundAudio.pause();
        this._backgroundAudio = null;
      }

      if (newScene.audioResourceId) {
        this._project.getResource({id: newScene.audioResourceId})
          .then((audioUrl) => {
            this._backgroundAudio = new Audio(audioUrl);
            this._backgroundAudio.play();
          });
      }

      const oldSceneHasVideo =
          !!this._sphere &&
          !!this._sphere._sphereMesh &&
          !!this._sphere._sphereMesh.material &&
          !!this._sphere._sphereMesh.material.map &&
          this._sphere._sphereMesh.material.map.image instanceof HTMLVideoElement,
        video = oldSceneHasVideo ? this._sphere._sphereMesh.material.map.image : null;

      if (oldSceneHasVideo) {
        video.setAttribute('preload', 'none');
        video.oncanplaythrough = () => {};
        video.pause();
        video.currentTime = 0;
      }

      this._state.sceneId = options.sceneId;
      this._state.rotateScene = this._player._config.rotateScene;
      let newSpheres = this._project.getSpheres({sceneId: this._state.sceneId}),
        animation,
        rotation;

      if (options.animationType == "fadeToBlack" || options.animationType == "fadeToWhite") {
        animation = options.animationType;
      }

      if (options.rotation && options.rotation.y !== undefined) {
        rotation = options.rotation;
      }

      if (animation) {
        this._sphere._overlayMesh.material.color = options.animationType == "fadeToBlack" ? new THREE.Color(0x000000) : new THREE.Color(0xffffff);
        this._animations.create({
          length: 200,
          update: (progress) => {
            this._sphere._overlayMesh.material.opacity = progress;
          },
          ease: Easings.inQuad,
        }).promise
          .then(() => {
            this._sphere.setSphere(newSpheres);
            this._widgetsManager.reloadWidgets({widgets: newScene.widgets});
            if (rotation) {
              this._controls.rotateTo(rotation);
            }

            this._animations.create({
              length: 200,
              update: (progress) => {
                this._sphere._overlayMesh.material.opacity = 1 - progress;
              },
              ease: Easings.inQuad,
            }).promise
              .then(() => {
                resolve();
                this.emit('changeScene', options);
              });
          });
      } else {
        this._widgetsManager.reloadWidgets({widgets: newScene.widgets});
        this._sphere.setSphere(newSpheres);

        if (rotation) {
          this._controls.rotateTo(rotation);
        }

        this.once('render', () => {
          resolve();
          this.emit('changeScene', options);
        });
      }
    });
  }
  nextScene(loop = false) {
    return new Promise((resolve, reject) => {
      let nextSceneId = this._project.getNextScene(this._state.sceneId, loop);

      if (nextSceneId) {
        this.changeScene({sceneId: nextSceneId})
          .then(resolve, reject);
      } else {
        reject();
      }
    });
  }
  previousScene() {
    return new Promise((resolve, reject) => {
      let previousSceneId = this._project.getPreviousScene(this._state.sceneId);

      if (previousSceneId) {
        this.changeScene({sceneId: previousSceneId})
          .then(resolve, reject);
      } else {
        reject();
      }
    });
  }
  startAutoplay({animationLength, rotation}) {
    const startAutoplay = () => {
      if (this._state.autoplay) {
        return;
      }

      this._state.autoplay = true;

      if (
        this._state.vrState !== State.PRESENTING &&
      this._project._data.scenes &&
      this._project._data.scenes.length
      ) {
        this._state.rotateScene = false;

        let startRotation = this.getCameraRotation();

        this._animations.create({
          id: 'autoplay',
          length: animationLength,
          update: (progress) => {
            let newRotation = {
              x: startRotation.x * (1 - progress),
              y: startRotation.y - (progress * rotation)
            };

            this._controls.rotateTo(newRotation);
          }
        }).promise.then(() => {
          this.nextScene(true).then(() => {
            this._state.autoplay = false;
            startAutoplay();
            if (this._state.sceneId === this._project._data.initialState.sceneId) {
              this.once('render', () => {
                this.emit('autoplay.restart');
              });
            }
          }, () => {
          //change scene to the first one
            const rotation = this._project._data.initialState.rotation;
            this.changeScene({sceneId: this._project._data.initialState.sceneId, rotation})
              .then(() => {
                this._state.autoplay = false;
                startAutoplay();
                this.once('render', () => {
                  this.emit('autoplay.restart');
                });
              });
          });
        }, () => {});
      }
    };

    if (this._state.autoplay) {
      return;
    }
    startAutoplay();
    this.emit('autoplay.start');
  }
  stopAutoplay() {
    if (!this._state.autoplay) {
      return;
    }

    this._state.autoplay = false;
    this._animations.break('autoplay');
    this.emit('autoplay.stop');
  }
  enableRotateScene() {
    this._state.rotateScene = true;
  }
  disableRotateScene() {
    this._state.rotateScene = false;
  }
  requestExit() {
    const isPresenting = this._webvrManager.state === 'presenting';

    if (isPresenting) {
      this._webvrManager.exitVR(this._webvrManager.defaultDisplay);

      return;
    }

    this._webvrManager.exitFullscreen();
  }
  getCameraRotation() {
    var cameraDirection,
      cameraRotation,
      angleY;

    cameraDirection = this._camera.getWorldDirection();
    this._scene.worldToLocal(cameraDirection);
    angleY = Math.atan2(-cameraDirection.x, -cameraDirection.z);
    cameraRotation = new THREE.Vector3(-Math.asin(cameraDirection.y), angleY, 0);

    if (Math.abs(cameraDirection.y) >= 1) {
      cameraRotation.x = (cameraDirection.y < 0 ? 1 : -1) * Math.PI / 2;
    }

    if (cameraRotation.y == 2 * Math.PI) {
      cameraRotation.y = 0;
    }

    return cameraRotation;
  }
  rotateCamera(options) {
    const shouldAnimate = !!options.animate;

    if (!shouldAnimate) {
      this._controls.rotateTo(options.rotation);

      return;
    }

    let cameraRotation = this.getCameraRotation(),
      rotation = options.rotation,
      subRotation;

    if (rotation.y < -(Math.PI / 2) && cameraRotation.y > (Math.PI / 2)) {
      rotation.y = 2 * Math.PI + rotation.y;
      subRotation = cameraRotation.clone().sub(rotation);
    } else if (cameraRotation.y < -(Math.PI / 2) && rotation.y > (Math.PI / 2)) {
      rotation.y = rotation.y - 2 * Math.PI;
      subRotation = cameraRotation.clone().sub(rotation);
    } else {
      subRotation = cameraRotation.clone().sub(rotation);
    }

    subRotation.x = -subRotation.x;
    subRotation.y = -subRotation.y;

    this._animations.create({
      id: 'rotateCamera',
      length: 1000,
      update: (progress) => {
        let x = cameraRotation.x + (subRotation.x * progress),
          y = cameraRotation.y + (subRotation.y * progress);
        this._controls.rotateTo({x, y});
      },
      ease: Utils.easeInOutCubic
    });
  }
  createWidget(data) {
    this._widgetsManager.createWidget({data: data, project: this._project});
  }
  openInistialState() {
    const hasInitialState =
      this._project._data.initialState &&
      this._project._data.initialState.sceneId;

    if (!hasInitialState) { return; }

    this.changeScene({
      sceneId: this._project._data.initialState.sceneId,
      rotation: this._project._data.initialState.rotation
    });
  }
  enterVR() {
    const isVrEnabled = this._state.vrEnabled;

    if (!isVrEnabled) {
      this.$l.warn('No vr display');

      return;
    }

    this._webvrManager.enterVR(
      this._webvrManager.defaultDisplay, this._renderer.domElement
    );
  }
  animate(timestamp) {
    if (this._stats) { this._stats.begin(); }

    let delta = Math.min(timestamp - this._lastRender, 500),
      raycaster,
      framerate = 60,
      start = Date.now();

    this._scene.updateMatrixWorld();
    this._lastRender = timestamp;

    this._animations.tick(delta);
    this._controls.update();
    this._pointerManager && this._pointerManager.animate({delta});
    this._renderer.render(this._scene, this._camera);

    if (this._state.rotateScene) {
      this._controls.rotateY(-0.0005);
    }

    if (this._state.vrState === 'presenting') { //provide raycaster for VR presenting
      this._raycaster.setFromCamera({x: 0, y: 0}, this._camera);
      raycaster = this._raycaster;
    }

    if (this._state.vrEnabled) {
      this._effect.render(this._scene, this._camera);
      if (this._controls._enabledEvents) {
        this._widgetsManager.animate({delta: delta, raycaster: raycaster});
      } else {
        this._widgetsManager.animate({delta: delta});
      }
    } else {
      if (!Utils.Browser.mobile) {
        this._widgetsManager.animate({delta: delta, raycaster: raycaster, mousePosition: this._mousePosition});
      } else {
        this._widgetsManager.animate({delta: delta, raycaster: raycaster});
      }
    }

    this.emit('render');

    if (this._stats) this._stats.end();

    let delay = Math.max(0, (start - Date.now()) + 1000/framerate);

    this._timeoutId = setTimeout(() => {
      if (this._state.animating) {
        this._animationFrameId = this._animationDisplay.requestAnimationFrame(this.animate.bind(this));
      } else {
        this._renderer.clear(true);
      }
    }, delay);
  }

  startAnimate() {
    this._state.animating = true;

    this._animationFrameId = window.requestAnimationFrame(this.animate.bind(this));
  }
  stopAnimate() {
    window.cancelAnimationFrame(this._animationFrameId);
    window.clearTimeout(this._timeoutId);
    this._state.animating = false;
  }
  onResize() {
    const width = this._ui.$.offsetWidth * this._scaleFactor;
    const	height = this._ui.$.offsetHeight * this._scaleFactor;
    const ratio = width / height;

    this._effect.setSize(width, height, true);
    this._camera.aspect = ratio;
    this._camera.updateProjectionMatrix();
  }
  setZoom({zoom = 1, animating = false}) {
    if (animating) {
      let startZoom = this._camera.zoom;

      this._animations.stop('camera-zoom');
      this._animations.create({
        id: 'camera-zoom',
        length: 500,
        update: (progress) => {
          this._camera.zoom = startZoom + progress * (zoom - startZoom);
          this._camera.updateProjectionMatrix();
        },
      });
    } else {
      this._camera.zoom = zoom;
      this._camera.updateProjectionMatrix();
    }
  }
  disableWidgets() {
    this._state.widgetsEnabled = false;
  }
  enableWidgets() {
    this._state.widgetsEnabled = true;
  }
  unload() {
    this.stopAnimate();
    if (this._backgroundAudio) {
      this._backgroundAudio.pause();
    }
    this._widgetsManager.reloadWidgets({widgets: []});
    this._sphere.remove();
    this._renderer.dispose();
    this._state.sceneId = null;
  }
  /**
   * Clear all event listeners
   */
  remove() {
    this.stopAnimate();
    if (this._backgroundAudio) {
      this._backgroundAudio.pause();
    }
    this._widgetsManager.reloadWidgets({widgets: []});
    this._sphere.remove();
    this._controls.remove();
    window.removeEventListener('resize', this.onResize, true);
    window.removeEventListener('vrdisplaypresentchange', this.onResize, true);
    this._state.animating = false;
  }
}

