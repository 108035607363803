/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Widget from './Widget'
import { Config } from 'evr'

export default class TransitionWidget extends Widget {
  constructor(props) {
    super(props);

    this.renderOrder = Config.player.renderOrder.transition + props.getRenderOrderInc();

    this._state.isReady = new Promise((resolve, reject) => {
      resolve();
    });
  }
  animate(options) {
    if (this._state.initialized) {
      if (this._state.opening || this._state.closing) {
        let delta = options.delta,
          progress = this._state.animationProgress,
          totalAnimationTime = Config.player.widgets.animationTime,
          normalizedProgress;

        if (this._state.opening) {
          progress += delta;
        } else {
          progress -= delta;
        }

        progress = (progress < 0 ? 0 : (progress > totalAnimationTime ? totalAnimationTime : progress));
        normalizedProgress = (+(progress / totalAnimationTime)).toFixed(2);

        if (normalizedProgress >= 1) {
          this._state.closed = false;
          this._state.opening = false;
          this._state.opened = true;
          progress = totalAnimationTime;
        } else if (normalizedProgress <= 0) {
          this._state.closed = true;
          this._state.opened = false;
          this._state.closing = false;
          progress = 0;
        }

        this._icon.drawIcon(normalizedProgress);
        this._state.animationProgress = progress;

        if (normalizedProgress >= 1) {
          if (this._data.details.destination) {
            if (this._data.details.destination.sceneId) {
							this.emit('open', {
                widgetId: this._data.id,
								action: 'changeScene',
								options: {
									sceneId: this._data.details.destination.sceneId,
									animationType: this._data.details.destination.animationType,
									rotation: this._data.details.destination.rotation
								}
							});
            } else if (this._data.details.destination.projectHash) {
							this.emit('open', {
                widgetId: this._data.id,
								action: 'openProject',
								options: {
									projectHash: this._data.details.destination.projectHash
								}
							});
            }
          }
        }
      }
    }
  }
}

