/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Utils from 'Utils/Utils.js';

export default class Loader {
  constructor({ parent, brand = null}) {
    this.$ = Utils.$({
      cssClass: 'evr-loader'
    });

    let loaderProgress = Utils.$({
      cssClass: 'loader-progress'
    });

    var strVar="";
    
    if (brand && brand.toUpperCase() === "JLL") {
      strVar += '<svg version="1.1" id="loader2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-1 -1 359.2 179.9" xml:space="preserve">' +
        '<path class="st0" d="M43.1,12.6c-4,0-7.7,0.8-11.1,2.3s-6.5,3.7-9.2,6.7s-4.5,6.3-5.6,9.8c-0.8,2.7-1.3,5.6-1.4,8.5h-6\n' +
        'c-2.7,0-5.2,1.1-7,2.9c-1.8,1.8-2.9,4.3-2.9,7v49.4c0,2.7,1.1,5.2,2.9,7s4.3,2.9,7,2.9h8l1.4,43.8c0,7.1,3.2,13.3,8.1,17.7\n' +
        'c5.1,4.6,12,7.4,19.2,7.4h93.3c5.2,0,10.1-1.4,14.2-3.9c4.2-2.5,7.6-6.2,10-10.8l0.6-1.1c1.2-2.3,2.9-4.1,5-5.4\n' +
        'c2.1-1.2,4.5-1.9,7.1-1.9h5c2.6,0,5,0.7,7.1,1.9s3.8,3.1,5,5.4l0.6,1.1c2.4,4.6,5.9,8.3,10,10.8c4.2,2.5,9,3.9,14.2,3.9H313\n' +
        'c6.8-0.1,13.4-2.5,18.4-6.7c5.1-4.3,8.5-10.3,8.5-17.3l0.9-45h7.3c2.7,0,5.2-1.1,7-2.9s2.9-4.3,2.9-7V49.8c0-2.7-1.1-5.2-2.9-7\n' +
        's-4.3-2.9-7-2.9h-6c-0.1-2.7-0.5-5.2-1.1-7.7c-1-3.7-2.6-7.1-5.3-10.2c-2.7-3.1-5.8-5.4-9.3-7s-7.3-2.4-11.4-2.4H213.5V9.9\n' +
        'c0-2.7-1.1-5.2-2.9-7c-1.8-1.8-4.3-2.9-7-2.9h-44.4c-2.7,0-5.2,1.1-7,2.9s-2.9,4.3-2.9,7v2.7L43.1,12.6L43.1,12.6z"/>\n' +
        '<path class="st1" d="M43.1,12.6c-4,0-7.7,0.8-11.1,2.3s-6.5,3.7-9.2,6.7s-4.5,6.3-5.6,9.8c-0.8,2.7-1.3,5.6-1.4,8.5h-6\n' +
        'c-2.7,0-5.2,1.1-7,2.9c-1.8,1.8-2.9,4.3-2.9,7v49.4c0,2.7,1.1,5.2,2.9,7s4.3,2.9,7,2.9h8l1.4,43.8c0,7.1,3.2,13.3,8.1,17.7\n' +
        'c5.1,4.6,12,7.4,19.2,7.4h93.3c5.2,0,10.1-1.4,14.2-3.9c4.2-2.5,7.6-6.2,10-10.8l0.6-1.1c1.2-2.3,2.9-4.1,5-5.4\n' +
        'c2.1-1.2,4.5-1.9,7.1-1.9h5c2.6,0,5,0.7,7.1,1.9s3.8,3.1,5,5.4l0.6,1.1c2.4,4.6,5.9,8.3,10,10.8c4.2,2.5,9,3.9,14.2,3.9H313\n' +
        'c6.8-0.1,13.4-2.5,18.4-6.7c5.1-4.3,8.5-10.3,8.5-17.3l0.9-45h7.3c2.7,0,5.2-1.1,7-2.9s2.9-4.3,2.9-7V49.8c0-2.7-1.1-5.2-2.9-7\n' +
        's-4.3-2.9-7-2.9h-6c-0.1-2.7-0.5-5.2-1.1-7.7c-1-3.7-2.6-7.1-5.3-10.2c-2.7-3.1-5.8-5.4-9.3-7s-7.3-2.4-11.4-2.4H213.5V9.9\n' +
        'c0-2.7-1.1-5.2-2.9-7c-1.8-1.8-4.3-2.9-7-2.9h-44.4c-2.7,0-5.2,1.1-7,2.9s-2.9,4.3-2.9,7v2.7L43.1,12.6L43.1,12.6z"/>' +
        '</svg>';
      this.$.classList.add(brand);
    } else {
      strVar += '<svg version="1.1" id="loader1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 99 113" xml:space="preserve">' +
        '<path class="st1" stroke-width="2" stroke="#000" d="M81,12.6c-5.4,0-9.6,4.3-9.6,9.7V46c0,3-1.5,5.5-4,6.5c-2.5,1-5.4,0.3-7.6-1.9L28,15.4    c-3.6-3.6-9.8-3.7-13.5-0.2c-3.8,3.6-2.9,9.4,0.1,13L53,70.6l0,0.1c1.1,1.1,1.5,2.7,1,3.9c-0.5,1.3-1.8,2-3.5,2H24.2    c-5.7,0-9.1,4.6-9.1,9.1c0,5.6,3.8,9.7,9.1,9.7H81c5.2,0,9.1-4.2,9.1-9.7V22.3C90.1,17,86,12.6,81,12.6z"></path></svg>';
    }
    
    loaderProgress.innerHTML = strVar;

    this.$.appendChild(loaderProgress);

    if (parent) {
      parent.appendChild(this.$);
    }
  }
  set({progress = -1, error}) {
    if (!this.isHidden()) {
      if (progress > -1) {
        this.setPercentage(progress);
      }
      
      if (error === 1) {
        this.$.classList.add('isError');
      } else {
        this.$.classList.remove('isError');
      }
    } else {
      this.show();
    }
  }
  setPercentage(progress) {
    let path = this.$.querySelector('path.st1'),
      length = path.getTotalLength();
    path.style.strokeDashoffset = length - length * progress /100;
  }
  isHidden() {
    return this.$.className.match(/\bisHidden\b/);
  }
  show() {
    this.$.classList.remove('isHidden');
    this.$.classList.remove('isError');
  }
  hide() {
    this.setPercentage(0);
    this.$.classList.add('isHidden');
  }
}

