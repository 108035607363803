/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Utils from 'Utils/Utils';
import Logger from 'Utils/Logger';
import EventsEmitter from './../Utils/EventsEmitter';
import InfopointWidget from './InfopointWidget';
import BannerWidget from './BannerWidget';
import PictureWidget from './PictureWidget';
import VideoWidget from './VideoWidget';
import TransitionWidget from './TransitionWidget';
import _minBy from 'lodash/minBy';

export default class WidgetsManager extends EventsEmitter {
  constructor({stage, scene, project, editor, speaker, animations, linksEnabled, audioEnabled}) {
    super();
    this._widgets = {};
    this._scene = scene;
    this._stage = stage;
    this._project = project;
    this.$l = new Logger('WidgetsManager');
    this.$ = new THREE.Group();
    this.$.name = 'WidgetsManager';
    this._scene.add(this.$);
    this._initialized = false; // if true, runs init on widgets with init method
    this._editor = editor;
    this._speaker = speaker;
    this._renderOrderInc = 0; // add incremental number to render order
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this._getRenderOrderInc = this._getRenderOrderInc.bind(this);
    this.animations = animations;
    this.linksEnabled = linksEnabled;
    this.audioEnabled = audioEnabled;
  }
  _getRenderOrderInc() {
    return this._renderOrderInc++;
  }
  createWidget(widgetData) {
    const { id } = widgetData;
    const isIdInvalid = !id;
    const widgetAlreadyExists = !!this._widgets[id];

    if (isIdInvalid) {
      this.$l.error(`Provided invalid widget id: ${id}`);
    }

    if (widgetAlreadyExists) {
      this.$l.error(`Widget with given id: ${id} already exists`);
    }

    try {
      const widgetType = widgetData.type;
      const availableWidgets = {
        'infopoint': InfopointWidget,
        'banner': BannerWidget,
        'picture': PictureWidget,
        'video': VideoWidget,
        'transition': TransitionWidget,
        'project-link': TransitionWidget
      };

      const ChosenWidget = availableWidgets[widgetType];
      const isChosenWidgetAvailable = !!ChosenWidget;

      if (!isChosenWidgetAvailable) {
        throw new Error('Unknown widget type: ' + widgetType);
      }

      const newWidget = new ChosenWidget({
        data: widgetData,
        project: this._project,
        editor: this._editor,
        speaker: this._speaker,
        getRenderOrderInc: this._getRenderOrderInc,
        animations: this.animations,
        linksEnabled: this.linksEnabled,
        audioEnabled: this.audioEnabled,
      });

      this._widgets[id] = newWidget;

      newWidget.getState().isReady.then(() => {
        const createdWidget = this._widgets[id];

        createdWidget.on('open', this.handleOpen);
        createdWidget.on('close', this.handleClose);
        createdWidget.on('openLink', e => {
          this.emit('openLink', e);
        });
        createdWidget.on('openLinkStart', e => {
          this.emit('openLinkStart', e);
        });
        createdWidget.on('openLinkEnd', e => {
          this.emit('openLinkEnd', e);
        });
        createdWidget.on('changePresentation', e => {
          this.emit('changePresentation', e);
        });

        this.$.add(createdWidget.$);
      }, () => {
        delete this._widgets[id];
      });

      this._initialized = false;

      return newWidget;
    } catch(e) {
      this.$l.warn(e);
    }
  }
  reloadWidgets({widgets}) {
    let currentWidgets = Utils.extend({arr: widgets}).arr;

    for (let widgetId in this._widgets) {
      this.removeWidget(widgetId);
    }

    if (!currentWidgets) { return; }

    currentWidgets.forEach(widget => this.createWidget(widget));
  }
  animate(options) {
    if (!this._initialized) {
      this.init();
    } else {
      for (let widgetId in this._widgets) {
        let widget = this._widgets[widgetId];
        if (!widget) { break; }

        if (options.raycaster) {
          if (widget.intersectObject({raycaster: options.raycaster})) {

            if (!widget.getState().opened) {
              widget.open();
            }
          } else {
            if (widget.getState().opened) {
              widget.close();
            }
          }
        }

        if (!this._editor) {
          widget.animateIcon(options);
        }
        widget.animate(options);
      }
    }
  }
  init() {
    if (!this._initialized) {
      let promises = [];
      for (let widgetId in this._widgets) {
        if (this._widgets[widgetId].init) {
          promises.push(this._widgets[widgetId].init());
        }
      }
      Promise.all(promises).then(() => {
        this._initialized = true;
      }, (e) => {
        this.$l.error(e);
      });
    }
  }
  closeWidget(options) {
    let closedIds = [];

    if (options && options.widgetId) {
      if (this._widgets[options.widgetId]) {
        this._widgets[options.widgetId].close({animate: !!options.animating});
        if (this._widgets[options.widgetId].updateHighlightMesh) {
          this._widgets[options.widgetId].updateHighlightMesh();
        }
        closedIds.push(options.widgetId);
      } else {
        this.$l.warn('There is no widget with given id on this scene');
      }
    } else {
      for (let widgetId in this._widgets) {
        let widget = this._widgets[widgetId];
        if (widget._state.opening || widget._state.opened) {
          this._widgets[widgetId].close(!!(options && options.animating));
          if (this._widgets[widgetId].updateHighlightMesh) {
            this._widgets[widgetId].updateHighlightMesh();
          }
          closedIds.push(widgetId);
        }
      }
    }

    return closedIds;
  }
  openWidget(options) {
    let widget = this._widgets[options.widgetId];

    if (widget) {
      if (!this._editor || widget._data.type !== 'transition') {
        widget.open(!!options.animating);
        if (widget.updateHighlightMesh) {
          widget.updateHighlightMesh();
        }
      }
    } else {
      this.$l.warn('There is no widget with id: ' + options.widgetId);
    }
  }
  toggleNearestWidget(cameraForward) {
    // distance < 0.6 is magic number for angle of ~35 degrees
    let widgets = Object.entries(this._widgets).map(([id, widget]) => {
      let widgetDirection = widget.$.getWorldDirection().clone().negate();
      return {id: id, widget: widget, distance: widgetDirection.angleTo(cameraForward)};
    }).filter(({distance, widget}) => distance < 0.6 && (widget._data.type === 'infopoint' || widget._data.type === 'transition' || widget._data.type === 'project-link'));

    let closestWidget,
      wasOpened = false;

    if (widgets.length) {
      closestWidget = _minBy(widgets, pair => pair.distance);
      wasOpened = closestWidget.widget._state.opening || closestWidget.widget._state.opened;
    }

    // close all other widgets
    this.closeWidget({animating: true});

    if (closestWidget) {
      if (!wasOpened) {
        this.openWidget({widgetId: closestWidget.id, animating: true});
      }
      this.emit('toggleNearestWidget', { target: { id: closestWidget.id }, effective: true });
    } else {
      this.emit('toggleNearestWidget', { effective: false });
    }
  }
  handleClick({raycaster}) {
    let widgetsClicked = [],
      widgetsToClose = [],
      effective;

    for (let widgetId in this._widgets) {
      let widget = this._widgets[widgetId];

      if (widget._state.opening || widget._state.opened) {
        widgetsToClose.push(widgetId);
      }
      if (widget.intersectObject({raycaster: raycaster})) {
        widgetsClicked.push(widget);
      }
    }

    widgetsClicked.sort((w1, w2) => (w2.renderOrder || 0) - (w1.renderOrder || 0));

    if (widgetsClicked.length) {
      const widgetToOpen = widgetsClicked[0]._data.id;
      const widgetIndex = widgetsToClose.indexOf(widgetToOpen);

      if (widgetIndex === -1) {
        this.openWidget({widgetId: widgetToOpen, animating: true});
      } else {
        widgetsToClose.splice(widgetIndex, 1);
        widgetsClicked = [];
      }
    }

    for (let i = 0; i < widgetsToClose.length; i++) {
      this.closeWidget({widgetId: widgetsToClose[i], animating: true});
    }

    effective = !!(widgetsClicked.length || widgetsToClose.length);

    return { targets: widgetsClicked, effective, };
  }
  handleMove(mousePosition) {
    for (let widgetId in this._widgets) {
      if (!this._widgets[widgetId].getState().opened) {
        this._widgets[widgetId].handleMove(mousePosition);
      }
    }
  }
  intersectWidgets({raycaster}) {
    let widgets = [];

    for (let widgetId in this._widgets) {
      if (this._widgets[widgetId].intersectObject({raycaster: raycaster})) {
        widgets.push(this._widgets[widgetId]);
      }
    }

    widgets.sort(function(w1, w2){
      return (w2.renderOrder || 0) - (w1.renderOrder || 0);
    });

    return widgets;
  }
  handleOpen(options) {
    this.emit('openWidget', options);
  }
  handleClose(options) {
    this.emit('closeWidget', options);
  }
  highlightWidgets(widgetIds) {
    for (let widgetId in this._widgets) {
      this._widgets[widgetId].setHighlight(widgetIds.indexOf(widgetId) > -1);
    }
  }
  removeWidget(widgetId) {
    if (widgetId && this._widgets[widgetId]) {
      this.$.remove(this._widgets[widgetId].$);
      this._widgets[widgetId].remove();
      delete this._widgets[widgetId];
    }
  }
  addWidget(widgetData) {
    this.createWidget(widgetData);
  }
  getWidget(widgetId) {
    return this._widgets[widgetId];
  }
  updateWidget(widgetData) {
    let widget = this._widgets[widgetData.id];

    return new Promise((resolve, reject) => {
      if (widget) {
        let diff = Utils.diff(widget._data, widgetData, 1),
          tiltChanged = false,
          typeChanged = false,
          rotationChanged = false,
          otherChanged = false;

        if (diff.indexOf('details.tilt') > -1) {
          tiltChanged = true;
          diff.splice(diff.indexOf('details.tilt'), 1);
        }

        if (diff.indexOf('type') > -1) {
          typeChanged = true;
          diff.splice(diff.indexOf('type'), 1);
        }

        if (diff.indexOf('rotation.y') > -1 || diff.indexOf('rotation.x') > -1) {
          rotationChanged = true;
          diff.indexOf('rotation.y') > -1 && diff.splice(diff.indexOf('rotation.y'), 1);
          diff.indexOf('rotation.x') > -1 && diff.splice(diff.indexOf('rotation.x'), 1);
        }

        if (diff.length > 0) otherChanged = true;

        if (typeChanged) {
          let wasHighlighted = widget.isHighlighted(),
            wasOpened = widget._state.opened;

          this.removeWidget(widgetData.id);
          let newWidget = this.createWidget(widgetData);

          newWidget.getState().isReady.then(() => {
            newWidget.setHighlight(wasHighlighted);
            if (wasOpened) newWidget.open(false);
            resolve();
          }, reject);
        } else if (otherChanged) {
          widget._data = widgetData;
          widget.update(diff)
            .then(resolve, reject);
        } else if (tiltChanged || rotationChanged) {
          if (rotationChanged) {
            widget._data.rotation = widgetData.rotation;
            widget.updateRotationPosition();
          }
          if (tiltChanged) {
            widget._data.details.tilt = widgetData.details.tilt;
            widget.updateTilt();
          }
          resolve();
        } else {
          resolve();
        }
      }});
  }
  removeLinks() {
    Object.entries(this._widgets).map(([id, widget]) => {
      if (widget._data.details.link) {
        if (widget.removeLink) {
          widget.removeLink();
        }
      }
    });
  }
  openCurrentLink() {
    const openWidget = Object.values(this._widgets).find(
      widget => (widget._state.opened || widget._state.opening)
    );

    if (openWidget && openWidget.onLinkOpen) {
      openWidget.onLinkOpen();
    }
  }
  closeCurrentLink() {
    const closeWidget = Object.values(this._widgets).find(
      widget => (widget._state.opened || widget._state.opening)
    );

    if (closeWidget && closeWidget.onLinkClose) {
      closeWidget.onLinkClose();
    }
  }
}

