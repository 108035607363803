/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Utils from 'Utils/Utils';
import VRDisplay from './VRDisplay';
import MouseKeyboardVRDisplay from './MouseKeyboardVRDisplay';
import {Quaternion, Vector3} from "three";

// Based on VRControls.js from THREE.js examples/controls
export default class DesktopControls {
  constructor(settings) {
    this._camera = settings.camera;

    this._vrInput = new MouseKeyboardVRDisplay(settings.element);
  }
  enableControls() {
    this._vrInput.enable();
  }
  disableControls() {
    this._vrInput.disable();
  }
  controlsEnabled() {
    return this._vrInput.controlsEnabled;
  }
  resetPose() {
    this._vrInput.rotateCamera({x: -this._vrInput.phi_, y: 0});
  }
  rotateCamera(rotation) {
    this._vrInput.rotateCamera(rotation);
  }
  update(gyroOrientation) {
    if (this._vrInput) {
      var pose = this._vrInput.getPose();
      if (pose.orientation !== null) {
        if (gyroOrientation) {
          const gyroQuaternion = new Quaternion().fromArray(gyroOrientation);

          const theta = this._vrInput.theta_;
          const thetaQuaterionion = new Quaternion().setFromAxisAngle({x:0, y:1, z:0}, theta);
          gyroQuaternion.premultiply(thetaQuaterionion);

          const phi = this._vrInput.phi_;
          const cameraXUnit = new Vector3(1, 0, 0).applyQuaternion(gyroQuaternion);
          const phiQuaternion = new Quaternion().setFromAxisAngle(cameraXUnit, phi);

          gyroQuaternion.premultiply(phiQuaternion);

          this._camera.quaternion.copy(gyroQuaternion);
        }
        else{
          let inputQuaternion = new Quaternion().fromArray(pose.orientation);
          this._camera.quaternion.copy(inputQuaternion);
        }
      }
      if (pose.position !== null) {
        this._camera.position.fromArray(pose.position);
      } else {
        this._camera.position.set(0, 0, 0);
      }

      this._camera.position.multiplyScalar(1);
    }
  }
}

