/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

const Config = __CONFIG__;
const DefaultProject = {
  initialState: {},
  resources: {
    // Icons
    "arrow-bottom": {id: "arrow-bottom", type: "@ContentIcon", name: "arrow-bottom", path: 'arrow-bottom.png'},
    "arrow-bottom-left": {id: "arrow-bottom-left", type: "@ContentIcon", name: 'arrow-bottom-left', path: 'arrow-bottom-left.png'},
    "arrow-bottom-right": {id: "arrow-bottom-right", type: "@ContentIcon", name: 'arrow-bottom-right', path: 'arrow-bottom-right.png'},
    "arrow-left":  {id: "arrow-left", type: "@ContentIcon", name: 'arrow-left', path: 'arrow-left.png'},
    "arrow-right": {id: "arrow-right", type: "@ContentIcon", name: 'arrow-right', path: 'arrow-right.png'},
    "arrow-top": {id: "arrow-top", type: "@ContentIcon", name: 'arrow-top', path: 'arrow-top.png'},
    "arrow-top-left": {id: "arrow-top-left", type: "@ContentIcon", name: 'arrow-top-left', path: 'arrow-top-left.png'},
    "arrow-top-right": {id: "arrow-top-right", type: "@ContentIcon", name: 'arrow-top-right', path: 'arrow-top-right.png'},
    "audio": {id: "audio", type: "@ContentIcon", name: 'audio', path: 'audio.png'},
    "audio-text": {id: "audio-text", type: "@ContentIcon", name: 'audio-text', path: 'audio-text.png'},
    "blank":  {id: "blank", type: "@ContentIcon", name: 'blank', path: 'blank.png'},
    "eye":  {id: "eye", type: "@ContentIcon", name: 'eye', path: 'eye.png'},
    "floorplan": {id: "floorplan", type: "@ContentIcon", name: 'floorplan', path: 'floorplan.png'},
    "image-text": {id: "image-text", type: "@ContentIcon", name: 'image-text', path: 'image-text.png'},
    "landscape": {id: "landscape", type: "@ContentIcon", name: 'landscape', path: 'landscape.png'},
    "panorama": {id: "panorama", type: "@ContentIcon", name: 'panorama', path: 'panorama.png'},
    "plus": {id: "plus", type: "@ContentIcon", name: 'plus', path: 'plus.png'},
    "ruler": {id: "ruler", type: "@ContentIcon", name: 'ruler', path: 'ruler.png'},
    "size": {id: "size", type: "@ContentIcon", name: 'size', path: 'size.png'},
    "text": {id: "text", type: "@ContentIcon", name: 'text', path: 'text.png'},
    "x": {id: "x", type: "@ContentIcon", name: 'x', path: 'x.png'},
    "jll_pin_blue": {id: "jll_pin_blue", type: "@ContentIcon", name: 'jll_pin_blue', path: 'jll_pin_blue.png'},
    "jll_pin_blue_active": {id: "jll_pin_blue_active", type: "@ContentIcon", name: 'jll_pin_blue_active', path: 'jll_pin_blue_active.png'},
    "jll_pin_red": {id: "jll_pin_red", type: "@ContentIcon", name: 'jll_pin_red', path: 'jll_pin_red.png'},
    "jll_pin_red_active": {id: "jll_pin_red_active", type: "@ContentIcon", name: 'jll_pin_red_active', path: 'jll_pin_red_active.png'},
    "num-1": {id: "num-1", type: "@ContentIcon", name: 'num-1', path: 'num-1.png'},
    "num-2": {id: "num-2", type: "@ContentIcon", name: 'num-2', path: 'num-2.png'},
    "num-3": {id: "num-3", type: "@ContentIcon", name: 'num-3', path: 'num-3.png'},
    "num-4": {id: "num-4", type: "@ContentIcon", name: 'num-4', path: 'num-4.png'},
    "num-5": {id: "num-5", type: "@ContentIcon", name: 'num-5', path: 'num-5.png'},
    "num-6": {id: "num-6", type: "@ContentIcon", name: 'num-6', path: 'num-6.png'},
    "num-7": {id: "num-7", type: "@ContentIcon", name: 'num-7', path: 'num-7.png'},
    "num-8": {id: "num-8", type: "@ContentIcon", name: 'num-8', path: 'num-8.png'},
    "num-9": {id: "num-9", type: "@ContentIcon", name: 'num-9', path: 'num-9.png'},
    "num-10": {id: "num-10", type: "@ContentIcon", name: 'num-10', path: 'num-10.png'},

    "image-placeholder": {id: "image-placeholder", type: "@ContentImage", path: Config.baseUrl + '/static/player/image-placeholder.png'},
    "image-broken": {id: "image-broken", type: "@ContentImage", path: Config.baseUrl + '/static/player/image-broken.png'},
    "video": {id: "video", type: "@ContentIcon", name: 'video', path: 'video.png'},
    "video-broken": {id: "video-broken", type: "@ContentImage", path: Config.baseUrl + '/static/player/video-broken.png'},
    "video-text": {id: "video-text", type: "@ContentIcon", name: 'video-text', path: 'video-text.png'},
    "fonts": [
      {id: 'font1', type: "@Font", name: 'Arvo', types: ['400', '400i', '700', '700i']},
      {id: 'font2', type: "@Font", name: 'Bangers', types: ['400']},
      {id: 'font3', type: "@Font", name: 'Dancing Script', types: ['400', '700']},
      {id: 'font4', type: "@Font", name: 'Lato', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font5', type: "@Font", name: 'Limelight', types: ['400']},
      {id: 'font6', type: "@Font", name: 'Montserrat', types: ['400', '700']},
      {id: 'font7', type: "@Font", name: 'Roboto', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font8', type: "@Font", name: 'Rubik', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font9', type: "@Font", name: 'Source Sans Pro', types: ['200', '300', '400', '600', '700']},
      {id: 'font10', type: "@Font", name: 'Ubuntu', types: ['300', '300i', '400', '400i', '700', '700i']}
    ],
    "spheres": [
      {
        "id": 'spherePlaceholder',
        "type": '@ContentSphere',
        "path": Config.baseUrl + '/static/player/sphere-placeholder.png',
        "pathThumbnail": Config.baseUrl + '/static/player/sphere-placeholder.png'
      },
      {
        "id": 'sphereBroken',
        "type": '@ContentSphere',
        "path": Config.baseUrl + '/static/player/sphere-broken.png',
        "pathThumbnail": Config.baseUrl + '/static/player/sphere-broken.png'
      },
    ],
  },
  scenes: [],
  context: '',
  style: {
    theme: 'DARK'
  },
  spaceplans: []
};

export default DefaultProject;

