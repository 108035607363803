/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Utils from 'Utils/Utils';
import { Config } from 'evr';

class Link {
  constructor(props) {
    const { 
      canvas,
      y,
      right,
      text, 
      url, 
      font, 
      fontSize, 
      color,
      secondaryColor,
      backgroundColor,
    } = props;

    this.canvas = canvas;
    this.url = url;
    this.text = text || 'Read more';
    this.font = font;
    this.color = color;
    this.secondaryColor = secondaryColor;
    this.backgroundColor = backgroundColor;
    this.y = y;
    this.right = right;
    this._disposable = [];
    this._scaleFactor = Config.player.scaleFactor.view * Config.player.ultimateMagicScaleFactor;
    this.fSize = fontSize * this._scaleFactor;
    this.lineHeight = this.fSize * 1.2;
    this.margin = [16 * this._scaleFactor, 5 * this._scaleFactor]; // [ horizontal, vertical ]

    this._ctx = canvas.getContext('2d');

    this.width = this._ctx.measureText(this.text).width + this.margin[0];
    this.height = Math.max(this.calcHeightAndDraw(), 1) + this.margin[1];

    this._geometry = new THREE.PlaneGeometry(this.width / this._scaleFactor, this.height / this._scaleFactor);
    this._material = new THREE.MeshBasicMaterial({
      color: '#000',
      transparent: true,
      opacity: 0,
      depthTest: false,
      depthWrite: false,
    });
    this._disposable.push(this._geometry);
    this._disposable.push(this._material);

    this.$ = new THREE.Mesh(this._geometry, this._material);
    this.$.name = 'Link';
  }
  calcHeightAndDraw(color) {
    this._ctx.font = `${this.fSize}px ${this.font}`;
    this._ctx.textBaseline = "middle";    
    this._ctx.fillStyle = color || this.color;
    this._ctx.textAlign = 'center';

    return Utils.Canvas.wrapText({
      context: this._ctx,
      text: this.text,
      x: this.canvas.width - this.right - this.width / 2,
      y: this.y + (this.height || 0) / 2,
      lineHeight: this.lineHeight,
      font: `${this.fSize}px ${this.font}`,
    });
  }
  draw(progress) {
    const strokeWidth = 0.8 * this._scaleFactor;

    // clear
    this._ctx.clearRect(
      this.canvas.width - this.right - this.width + strokeWidth, 
      strokeWidth + this.y, 
      this.width - 2 * strokeWidth,
      this.height - 2 * strokeWidth,
    );

    // draw border
    Utils.Canvas.roundedPath(
      this._ctx, 
      this.canvas.width - this.right - this.width + strokeWidth, // x
      strokeWidth + this.y, // y
      this.width - 2 * strokeWidth, // width
      this.height - 2 * strokeWidth, // height
      1 * this._scaleFactor, // radius
    );
    this._ctx.lineWidth = strokeWidth;
    this._ctx.strokeStyle = this.color;
    this._ctx.stroke();

    // draw progress
    this._ctx.fillStyle = this.color;
    this._ctx.fillRect(
      this.canvas.width - this.right - this.width + strokeWidth, 
      strokeWidth + this.y, 
      (this.width - 2 * strokeWidth) * progress,
      this.height - 2 * strokeWidth,
    );

    // next draw only when it overlaps with existing draw
    this._ctx.globalCompositeOperation = 'source-atop';
    // draw text 
    this.calcHeightAndDraw(this.secondaryColor);

    // next draws below existing
    this._ctx.globalCompositeOperation = 'destination-over';

    // draw text
    this.calcHeightAndDraw();

    // draw background
    this._ctx.fillStyle = this.backgroundColor;
    this._ctx.fillRect(
      this.canvas.width - this.right - this.width + strokeWidth, 
      strokeWidth + this.y, 
      this.width - 2 * strokeWidth,
      this.height - 2 * strokeWidth,
    );

    // set composite to default
    this._ctx.globalCompositeOperation = 'source-over';
  }
  remove() {
    while (this._disposable.length) {
      let el = this._disposable.shift();

      el.dispose();
    }
    this.canvas.width = 0;
    this.canvas.height = 0;
    delete this.canvas;
  }
}

export default Link;
