/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Utils from './Utils'
import Pointer from './Pointer'

export default function PointerManager(settings) {
	var _pointers = [];
	var _viewport = null;
  this._drawnLines = [];
  var _currentLine = null;
  var _lineGeometry;
  this._lineMaterial;
  this._viewportBackgroundMaterial;

  this._laserPointer = new THREE.Mesh(new THREE.CircleGeometry(0.55, 32),
			new THREE.MeshBasicMaterial({
				color: '#FF0000',
				depthTest: false,
				depthWrite: false,
				transparent: true
			}));
  this._laserPointer.name = "laserPointer";
  this._laserPointer.position.set(0, 0, 0);
  this._laserPointer.lookAt(new THREE.Vector3(0, 0, 0));
  this._laserPointer.renderOrder = 5001;

  var laserHolder = new THREE.Group();
  laserHolder.add(this._laserPointer);
  this._laserPointer.scale.set(0, 0, 0);
  settings.scene.add(this._laserPointer);

	function _showCameraViewport(data) {

		function getVectorForData(vec) {
			return new THREE.Vector3(vec.x, vec.y, vec.z).multiplyScalar(100);
		}

		var geometry = new THREE.Geometry();
		geometry.vertices.push(getVectorForData(data.lt));
		geometry.vertices.push(getVectorForData(data.rt));
		geometry.vertices.push(getVectorForData(data.rb));
		geometry.vertices.push(getVectorForData(data.lb));
		geometry.vertices.push(getVectorForData(data.lt));

		if (!this._lineMaterial) {
      this._lineMaterial = this._createLineMaterial();
    }

		var line = new THREE.Line(geometry, this._lineMaterial);
		line.renderOrder = 5000;


		geometry.faces.push( new THREE.Face3(0,1,2) );
		geometry.faces.push( new THREE.Face3(2,3,0) );
		geometry.computeFaceNormals();

		if (!this._viewportBackgroundMaterial) {
      this._viewportBackgroundMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        opacity: 0.2,
        transparent: true,
        depthTest: false,
        depthWrite: false,
        side: THREE.BackSide
      });
    }

		var mesh = new THREE.Mesh(geometry, this._viewportBackgroundMaterial);
		mesh.renderOrder = 5000;

		var obj = new THREE.Group();
		obj.add(line);
		obj.add(mesh);

		if (_viewport != null) {
			settings.scene.remove(_viewport);
			// dispose objects removed from scene
			_viewport.children.forEach(child => {
			  if (child.geometry) {
          child.geometry.dispose();
        }
        if (child.material) {
          child.material.dispose();
        }
      });
		}
		_viewport = obj;
		settings.scene.add(_viewport);

	}

	function _addPointer(rotationObj) {
		_pointers.push(new Pointer({
			rotation: Utils.parseXYZToRadians(rotationObj),
			scene: settings.scene
		}));
	}

	function _clear() {
		for (var i = 0; i < _pointers.length; i++) {
			_pointers[i].remove();
		}
		_pointers = [];

		_clearAllLines();
	}

	function _clearAllLines() {
		if (_currentLine != null) {
      this._drawnLines.push(_currentLine);
      _lineGeometry = null;
      _currentLine = null;
		}
		_currentLine = null;
		for (var i = 0; i < this._drawnLines.length; i++) {
			var line = this._drawnLines[i];
			settings.scene.remove(line);
			line.geometry.dispose();
			line.material.dispose();
		}
    this._drawnLines = [];
	}

	this._createLineMaterial = function() {
    return new THREE.LineBasicMaterial({
      color: 0xFFFFFF,
      linewidth: 2,
      transparent: true,
      depthTest: false,
      depthWrite: false,
    });
  };

	this.moveLaserPointer = function (rotationObj) {
    this._laserPointer.scale.set(1, 1, 1);
		var position = new THREE.Vector3(rotationObj.x, rotationObj.y, rotationObj.z);
		position.normalize();
		position.multiplyScalar(100);
    this._laserPointer.position.set(position.x, position.y, position.z);
    this._laserPointer.lookAt(new THREE.Vector3(0, 0, 0));
		// var rotation = eVR.Utils.parseXYZToRadians(rotationObj);
		// _laserPointer.setRotationFromQuaternion(new THREE.Quaternion(0, 0, 0, 1));
		// _laserPointer.rotateY(rotation.y);
		// _laserPointer.rotateX(-rotation.x);
		// _laserPointer.rotateZ(rotation.z);

	};
	this.closeLaserPointer = function () {
    this._laserPointer.position.set(100000, 0, 0);
	};
	this.addLineSegment = function (rotationObj) {

		if (_lineGeometry == null) {
			_lineGeometry = new THREE.Geometry();
		}

		var position = new THREE.Vector3(rotationObj.x, rotationObj.y, rotationObj.z);
		position.normalize();
		position.multiplyScalar(100);

		_lineGeometry.vertices.push(position);

		if (!this._lineMaterial) {
      this._lineMaterial = this._createLineMaterial();
    }

		var line = new THREE.Line(_lineGeometry.clone(), this._lineMaterial);
		line.renderOrder = 10000;

		settings.scene.remove(_currentLine);
		settings.scene.add(line);
		_currentLine = line;


	};
	this.closeLine = function () {
		if (_currentLine != null) {
      this._drawnLines.push(_currentLine);
			_lineGeometry = null;
			_currentLine = null;
		}
	};
	this.clearAllLines = _clearAllLines;
	this.showCameraViewport = _showCameraViewport;
	this.hideCameraViewport = function () {
		if (_viewport != null) {
			settings.scene.remove(_viewport);
			_viewport = null;
		}
	};
	this.addPointer = _addPointer;
	this.clear = _clear;
	this.animate = function (options) {
		for (var i = 0; i < _pointers.length; i++) {
			if (_pointers[i].animate) {
				_pointers[i].animate(options.delta);
			} else {
				_pointers.splice(i, 1);
				i--;
			}
		}
	};
  this.getDrawings = function() {
    return this._drawnLines.map(({ geometry }) => {
      return geometry.vertices.map(({ x, y, z }) => ({ x, y, z }));
    });
  };
  this.setDrawings = function(lines) {
    lines.forEach(line => {
      line.forEach((segment, index) => {
        this.addLineSegment(segment);
        if (index === line.length - 1) {
          this.closeLine();
        }
      });
    });
  };
}

