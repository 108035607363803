/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Widget from './Widget';
import View from './View';
import {Config} from 'evr';

// reuse material for better performance
let lineMaterial;

export default class PictureWidget extends Widget {
  constructor(props) {
    super(props);

    this._meshContent;
    this.renderOrder = Config.player.renderOrder.picture + props.getRenderOrderInc();

    this._state.isReady = this.createContent()
      .then(() => {
        this.createHighlightMesh();
      });
  }
  createHighlightMesh() {
    if (this.getHighlightBounds && this._meshContent) {
      let visible = false;

      if (this.highlightMesh) {
        visible = this.highlightMesh.visible;
        this._mesh.remove(this.highlightMesh);
      }

      this.highlightMesh = new THREE.Group();
      var bounds = this.getHighlightBounds();

      this.highlightMesh.renderOrder = Config.player.renderOrder.highlight;

      if (!lineMaterial) {
        lineMaterial = new THREE.LineBasicMaterial({
          color: Config.player.widgets.highlightColor,
          linewidth: 2,
          transparent: true,
          depthTest: false
        });
      }

      var lineGeometry = new THREE.Geometry();
      lineGeometry.vertices.push(
        new THREE.Vector3(bounds.max.x, bounds.max.y, 0),
        new THREE.Vector3(bounds.min.x, bounds.max.y, 0),
        new THREE.Vector3(bounds.min.x, bounds.min.y, 0),
        new THREE.Vector3(bounds.max.x, bounds.min.y, 0),
        new THREE.Vector3(bounds.max.x, bounds.max.y, 0)
      );

      var line = new THREE.Line(lineGeometry, lineMaterial);
      line.renderOrder = Config.player.renderOrder.highlight;
      this.highlightMesh.add(line);
      this.highlightMesh.visible = visible;
      this._mesh.add(this.highlightMesh);
    }
  }
  getHighlightBounds() {
    this._meshContent.geometry.computeBoundingBox();
    return this._meshContent.geometry.boundingBox;
  }
  createContent() {
    let create = (resolve) => {
      return (img) => {
        if (this._meshContent) {
          this._mesh.remove(this._meshContent);
        }

        const margin = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          ...this._data.details.margin,
        };

        const backgroundColor = (this._data.details.background &&
          (this._data.details.backgroundColor || Config.player.widgets.themes[this._data.details.theme].background)
        );

        this.img = img;
        this._view = new View({
          style: {
            background: backgroundColor,
            margin,
          },
          elements: [{
            type: 'image',
            image: this.img,
            width: this._data.details.size.width,
            height: this._data.details.size.height
          }],
          width: this._data.details.size.width,
          height: this._data.details.size.height
        });
        this._view._mesh.renderOrder = this.renderOrder;
        this._meshContent = this._view._mesh;
        this._intersectArray.push(this._meshContent);
        this._mesh.add(this._meshContent);
        resolve();
      };
    };

    return new Promise((resolve, reject) => {
      this._project.getResource({id: this._data.details.resourceId || 'image-placeholder'}).then(create(resolve), (e) => {
        let error = "Image resource with id " + this._data.details.resourceId + " not found. Requesting image-broken";
        this.$l.warn(error);
        this._project.getResource({id: 'image-broken'}).then(create(resolve), (e) => {
          this.$l.warn('image-broken not found');
          resolve();
        });
      });
    });
  }
  remove() {
    super.remove();
    if (this._view) {
      this._view.remove();
    }
  }
}

