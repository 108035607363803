/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Logger from 'Utils/Logger';
import Utils from './Utils/Utils';
import Stage from './Stage';
import Ui from './Ui/Ui';
import Parser from './Project/Parser';
import Project from './Project/Project';
import EventsEmitter from './Utils/EventsEmitter';
import LocationManager from './Utils/LocationManager';
import { State as VrState } from 'webvr-ui/build/webvr-ui';
import * as Stats from "three/examples/js/libs/stats.min";
import { Config } from './evr';

// https://github.com/mrdoob/three.js/pull/12750
THREE.Group.prototype.isGroup = true;

// See https://github.com/mrdoob/three.js/pull/14483
const consoleWarn = window.console.warn,
  isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

window.console.warn = function() {
  if (isIE11) return;
  // filter "image is not power of two" and "image is too big" warnings from three.js
  if (typeof arguments[0] === 'string' &&
    (arguments[0].includes('image is not power of two') || arguments[0].includes('image is too big'))
  ) {
    // log it to console without second argument, which contains reference to `image`
    consoleWarn.call(null, arguments[0]);
  } else {
    // pass other warnings without changes
    consoleWarn.apply(null, arguments);
  }
};

/**
 * Class representing a player.
 *
 * @example
 * var player = new evr.Player({
 *  selector: '#scene1'
 * });
 *
 * player.on('click', function(e) {
 *  console.log(e);
 * });
 *
 * @extends EventsEmitter
 *
 * @emits Player#changeScene
 */
class Player extends EventsEmitter {
  /**
   * Create player
   * @param {object} options
   * @param {HTMLElement} options.element - Determines where to attach player. If not set, selector param is used.
   * @param {string} options.selector='.evr-player' - Determines where to attach player. Internally uses querySelector. If it's empty and there's no element param, player will attach to document.body
   * @param {boolean} options.rotateScene=true - Player will automatically rotate scene. Disabled if autoplay=true.
   * @param {boolean} options.autoplay=false - Player will automatically rotate and change scene. Disables rotateScene.
   * @param {boolean} options.forceDesktopControls=false - Player will use DesktopControls (mouse and touch events support).
   * @param {boolean} options.enableGyroscope=false - If false, gyroscope will be disabled.
   * @param {boolean} options.linksEnabled=true - If value is false player will disable click events on links, but they will be visible.
   * @param {boolean} options.audioEnabled=true - If set to false, audio in infopoints will be muted.
   * @param {boolean} options.videoSphericalEnabled=true - If set to false, video in scene brackground will be paused.
   */
  constructor({
    selector = '.evr-player',
    element,
    stats = false,
    rotateScene = true,
    manageState = true,
    manageUrl = true,
    editor = false,
    scaleFactor = 1,
    forceDesktopControls = false,
    enableGyroscope,
    autoplay = false,
    linksEnabled = true,
    audioEnabled = true,
    videoSphericalEnabled = true,
  }) {
    super();
    const that = this;

    this._config = {
      editor: editor,
      baseUrl: Config.baseUrl || '',
      assetUrl: Config.assetUrl || '',
      rotateScene: rotateScene,
    };

    this.$l = new Logger('Player');

    this._locationManager = new LocationManager(manageUrl);
    this._locationManager.on('popstate', (e) => {
      if (e.state && e.state.sceneId) {
        this._stage.changeScene(event.state);
      } else if (!Config.player.hideOverlayOnPopstateWithoutSceneId) {
        this._ui.loading({});
      }
    });
    //
    // // fix to avoid Safari navbars appearance on iPhone 5S
    // window.addEventListener('touchmove', e => e.preventDefault());

    if (stats === true) {
      this._stats = new Stats.default();
      this._stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
    }

    this._ui = new Ui({selector: selector, element: element, stats: this._stats});
    this._project = new Project({player: that, ui: this._ui});
    // ui may emit changeScene in spaceplans
    this._ui.on('changeScene', (e) => {
      this.changeScene({sceneId: e});
      this.closeSpaceplans();
    });

    let _forceDesktopControls = forceDesktopControls || window.location.search.indexOf('desktopControls') > -1;

    this._stage = new Stage({
      player: this,
      ui: this._ui,
      project: this._project,
      stats: this._stats,
      rotateScene: this._config.rotateScene,
      editor: editor,
      forceDesktopControls: _forceDesktopControls,
      enableGyroscope: enableGyroscope,
      scaleFactor,
      linksEnabled,
      audioEnabled,
      videoSphericalEnabled,
    });

    // Loading project and stage initialization promises.
    this.loadingPromise = null;
    this.initPromise = new Promise((resolve, reject) => {
      this._stage.initPromise.then(resolve, reject);
    });

    this._project.on('loading', () => {
      this._ui.loading({});
    });
    this._project.on('load', (e) => {
      this._ui.loading({progress: e});
    });
    this._project.on('loaded', (targetScene) => {
      let sceneId,
        rotation;

      this._ui.$.classList.remove('isLoading');
      this._ui.loading({progress: 100});

      if (targetScene && targetScene.sceneId) {
        sceneId = targetScene.sceneId;
      } else if (!this._config.editor) {
        sceneId = this._locationManager.getHash();
      } else if (this._stage._state.sceneId) {
        sceneId = this._stage._state.sceneId;
      }

      if (!sceneId || !this._project.getScene({sceneId: sceneId})) {
        sceneId = this._project._data.initialState.sceneId;
      }

      if (targetScene && targetScene.rotation) {
        rotation = targetScene.rotation;
      } else if (!this._stage._state.sceneId && this._stage._state.sceneId !== sceneId) {
        rotation = this._project._data.initialState.rotation;
      }

      if (manageState) {
        this._locationManager.replace({ sceneId, rotation });
      }

      this._stage.once('changeScene', () => {
        this._ui.updateSpaceplans(this._project._data.spaceplans, this._project._data.resources, this._stage._state.sceneId, this._project.getScene({sceneId: this._stage._state.sceneId}).name);
        this._ui.stopLoading();
      });
      this._stage.changeScene({sceneId, rotation});
      this._stage.stopAnimate();
      if (autoplay) {
        this.startAutoplay();
      }
      this._stage.startAnimate();
    });

    //Fire outside events
    this._stage.on('changeScene', (e) => {
      let sceneName = this._project.getScene({sceneId: e.sceneId}).name,
        resultObject = Object.assign({ sceneName }, e);

      if (manageState) {
        let sceneId = e.sceneId,
          hash = this._locationManager.getHash() || this._project._data.initialState.sceneId;

        if (hash !== sceneId) {
          this._locationManager.push({sceneId: sceneId, rotation: resultObject.rotation });
        }
      }

      if (resultObject.rotation) {
        resultObject.rotation = Utils.parseXYZToDegrees(resultObject.rotation);
      }

      this._ui.setSpaceplanScene(e.sceneId, sceneName);
      this._stage.once('render', () => {
        this.emit('changeScene', resultObject);
      });
    });
    this._stage._controls.on('cameraRotate', () => {
      this.emit('cameraRotate', this.getCameraRotation());
      this.emit('cameraViewportChanged', this.getCameraViewport());
    });
    this._stage.on('widgetPositionChanged', (widget) => {
      let rotation = Utils.parseXYZToDegrees(widget.rotation);

      this.emit('widgetPositionChanged', {
        id: widget.id,
        newRotation: new THREE.Vector3(
          rotation.x,
          rotation.y,
          rotation.z
        )
      });
    });
    this._stage.on('click', (e) => {
      let rotation = e.rotation?Utils.parseXYZToDegrees(e.rotation):{};

      if (e.targets.length) {
        this.emit('click', {position: e.position, rotation: rotation, target: 'widget', data: {id: e.targets[0]}, effective: e.effective, });
        const widgetType = e.widget._data.type;
        if((widgetType === 'infopoint' || widgetType === 'transition') && !this.editor) {
          let coords = e.position;
          let rotation = this._stage._controls.xyToRotation(e.position);
          this._stage.rotateCamera({coords, rotation, animate: true});
        }
      } else {
        this.emit('click', {position: e.position, rotation: rotation, effective: e.effective});
      }
    });
    this._stage.on('mouseMove', (e) => {
      this.emit('pointerMove', {dirPosition: e.dirPosition, position: e.position, isMouseDown: this._stage._controls.leftButtonDown});
    });
    this._stage.on('openWidget', (e) => {
      this.emit('openWidget', e);
    });
    this._stage.on('closeWidget', (e) => {
      this.emit('closeWidget', e);
    });
    this._stage.on('changeMode', (e) => {
      this.emit('changeMode', {newMode: e});
    });
    this._stage.on('mouseLeave', () => {
      this.emit('pointerLeave', {});
    });
    this._stage.on('mouseUp', () => {
      this.emit('mouseUp', {});
    });
    this._stage.on('drag', (e) => {
      this.emit('drag', e);
    });
    this._stage.on('drop', (e) => {
      e.rotation = Utils.parseXYZToDegrees(e.rotation);
    });
    this._stage.on('autoplay.start', () => {
      this.emit('autoplay.start');
    });
    this._stage.on('autoplay.stop', () => {
      this.emit('autoplay.stop');
    });
    this._stage.on('openLink', e => {
      this.emit('openLink', e);
    });
    this._stage.on('openLinkStart', e => {
      this.emit('openLinkStart', e);
    });
    this._stage.on('openLinkEnd', e => {
      this.emit('openLinkEnd', e);
    });
    this._stage.on('changePresentation', e => {
      this.emit('changePresentation', e);
    });
    this._stage.on('autoplay.restart', () => {
      this.emit('autoplay.restart');
    });
    this._stage.on('toggleNearestWidget', (e) => {
      this.emit('toggleNearestWidget', e);
    });
  }
  getCameraViewport() {
    let unproject = (x, y) => {
      var ret = (new THREE.Vector3(x, y, 1)).unproject(this._stage._camera);
      ret = ret.sub(this._stage._camera.position).normalize();
      this._stage._scene.worldToLocal(ret);
      return ret;
    };

    var w = 1,
      h = 1;

    if (this._stage._state.vrState == 'presenting') {
      w = 0.8;
      h = 0.8;
    }

    return {
      lt: unproject(-w, h),
      lb: unproject(-w, -h),
      rt: unproject(w, h),
      rb: unproject(w, -h),
      c: unproject(0, 0),
    };
  }
  getCameraRotation() {
    let cameraRotation = this._stage.getCameraRotation();
    return Utils.parseXYZToDegrees(cameraRotation);
  }
  /**
   * Changes current scene.
   *
   * @param {Object} options
   * @param {string} options.sceneId - destination sceneId
   * @param {string} [options.rotation] - destination rotation {x: rad, y: rad}
   * @param {string} [options.animationType] - possible values: 'fadeToBlack', 'fadeToWhite'
   */
  changeScene(options) {
    this.loadingPromise.then(() => {
      let newScene = this._project.getScene({sceneId: options.sceneId}),
        rotation;

      if (options.rotation) {
        rotation = Utils.parseXYZToRadians(options.rotation);
      } else if (newScene && newScene.rotation) {
        rotation = newScene.rotation;
      } else {
        rotation = {x:0,y:0,z:0};
      }

      this._stage.changeScene({
        sceneId: ''+options.sceneId,
        rotation: rotation,
        animationType: options.animationType })
        .then(() => {}, () => {});
    });
  }
  /**
   * Changes current scene to previous visible.
   * @return {Promise} Resolved after scenes has changed.
   */
  previousScene() {
    return this._stage.previousScene();
  }
  /**
   * Changes current scene to next visible.
   * @return {Promise} Resolved after scenes has changed.
   */
  nextScene() {
    return this._stage.nextScene();
  }
  /**
   * Load new project, reloads resources, sets target scene.
   *
   * @param newProject=null {Object} - New project json
   * @param targetScene=null {Object} - {sceneId, {x:deg, y:deg, z:deg}}
   * @param loadFonts=true {Boolean} - Determines if fonts should be reloaded
   * @returns {Promise<void> | *}
   */
  load(newProject, targetScene, loadFonts = true) {
    this.loadingPromise = new Promise((resolve, reject) => {
      let target;

      if (targetScene && targetScene.sceneId) {
        target = {
          sceneId: ''+targetScene.sceneId
        };

        if (targetScene.rotation) {
          target.rotation = Utils.parseXYZToRadians(targetScene.rotation);
        }
      }

      this._ui.$.classList.add('isLoading');
      if (newProject instanceof Promise) {
        newProject.then((e) => {
          this._project.load(Parser.parseProject(e), target, loadFonts)
            .then(resolve, reject);
        }, reject);
      } else {
        this._project.load(Parser.parseProject(newProject), target, loadFonts)
          .then(resolve, reject);
      }
    });

    return this.loadingPromise;
  }
  /**
   * Unload current project
  */
  unload() {
    this._stage.unload();
    this._ui.stopLoading();
    this._ui.removeSpaceplans();
    this._ui._overlay.show();
    this._project.remove();
  }
  /**
   * Destroys player and clears all related event listeners.
   */
  remove() {
    this._stage.remove();
    this._project.remove();
    this._ui.remove();
  }
  highlightWidgets(widgetIds) {
    //highlight widget when widget.id is in arr widgetIds, unhighlight in other case
    let ids = widgetIds || [];
    this._stage._widgetsManager.highlightWidgets(ids);
  }
  removeWidget(widgetId) {
    this.loadingPromise.then(() => {
      this._stage._widgetsManager.removeWidget(widgetId);
      this._project.removeWidget(widgetId);
    });
  }
  addPointer(data) {
    this._stage._pointerManager.addPointer(data);
  }
  moveLaserPointer(data){
    this._stage._pointerManager.moveLaserPointer(data);
  }
  closeLaserPointer(data){
    this._stage._pointerManager.closeLaserPointer(data);
  }
  addLineSegment(data){
    this._stage._pointerManager.addLineSegment(data);
  }
  closeLine(data) {
    this._stage._pointerManager.closeLine(data);
  }
  clearAllLines() {
    this._stage._pointerManager.clearAllLines();
  }
  /**
   * @typedef {Object} Vertex
   * @property {Number} x.
   * @property {Number} y.
   * @property {Number} z.
   */

  /**
   * Get all line drawings present on current scene.
   *
   * @returns {Array.Array.<Vertex>}
   * @example output
   * // array of lines
   * [
   *    // array of line vertices
   *    [
   *      { x: 1, y: 1, z: 1 },
   *      { x: 2, y: 2, z: 2 },
   *    ],
   *    [
   *      { x: 3, y: 3, z: 3 },
   *      { x: 4, y: 4, z: 4 },
   *    ]
   * ]
   */
  getDrawings() {
    return this._stage._pointerManager.getDrawings();
  }
  /**
   * Get all line drawings present on current scene.
   *
   * @param {Array.Array.<Vertex>} lines - lines to draw.
   *
   * @example input
   * // array of lines
   * [
   *    // array of line vertices
   *    [
   *      { x: 1, y: 1, z: 1 },
   *      { x: 2, y: 2, z: 2 },
   *    ],
   *    [
   *      { x: 3, y: 3, z: 3 },
   *      { x: 4, y: 4, z: 4 },
   *    ]
   * ]
   */
  setDrawings(lines) {
    this._stage._pointerManager.setDrawings(lines);
  }
  addResource(resource) {
    let newResource = Utils.extend(resource);
    newResource.id = ''+newResource.id;
    return this._project.addResource(newResource);
  }
  /**
   * Add widget to the current scene or scene with provided sceneId
   *
   * @param {Object} widget - Widget object to add.
   * @param {String} sceneId - Scene id (optional)
   * @return {Promise} Resolved after widget has been added.
   */
  addWidget(widget, sceneId) {
    return new Promise((resolve, reject) => {
      this.loadingPromise.then(() => {
        let widgetData = Parser.parseWidget(widget);

        this._project.addWidget(sceneId || this._stage._state.sceneId, widgetData);
        let newWidget = this._stage._widgetsManager.createWidget(widgetData);
        newWidget.getState().isReady.then(resolve, reject);
      });
    });
  }
  /**
   * Open widget.
   *
   * @param {Object} options
   * @param {string} options.widgetId - widget id
   * @param {boolean} options.animating - if true, widget will open with animation. Type of animation can be set in widget details
   */
  openWidget(options) { this._stage._widgetsManager.openWidget(options); }
  /**
   * Close widget.
   *
   * @param {Object} options
   * @param {string} options.widgetId - widget id
   * @param {boolean} options.animating - if true, widget will open with animation. Type of animation can be set in widget details
   */
  closeWidget(options) { this._stage._widgetsManager.closeWidget(options); }
  openMenuScene() {
    let data = this._project._data;
    if (data && data.menuScene && data.menuScene.sceneId) {
      this.changeScene(data.menuScene);
    } else {
      this.$l.warn('Invalid menuScene');
    }
  }
  updateWidget(widget) {
    let widgetData = Parser.parseWidget(widget);
    this._project.updateWidget(widgetData);
    widgetData = this._project.getWidget(widgetData.id);
    return this._stage._widgetsManager.updateWidget(widgetData);
  }
  showCameraViewport(data) { this._stage._pointerManager.showCameraViewport(data); }
  hideCameraViewport() { this._stage._pointerManager.hideCameraViewport(); }
  /**
   * Disable triggering mouse and touch events,
   * only mouse move will be passed to higlight widgets close to cursor
   */
  disableControls() {
    this._stage._controls.initPromise.then(() => {
      this._stage._controls.disable();
    });
  }
  /**
   * Enable triggering mouse and touch events
   */
  enableControls() {
    this._stage._controls.initPromise.then(() => {
      this._stage._controls.enable();
    });
  }
  /**
   * @returns {Promise} - promise resolves to:
   *   - boolean if gyroscope is available
   *   - null if gyroscope is not available
   */
  isGyroscopeEnabled() {
    return new Promise((resolve, reject) => {
      this.isVRCompatible().then((isVRCompatible) => {
        resolve(isVRCompatible ? this._stage._controls._enabledGyroscope : null);
      }).catch(reject);
    });
  }
  /**
   * Disable gyroscope events
   */
  disableGyroscope() {
    const promise = this._stage._controls.initPromise;
    promise.then(() => { this._stage._controls.disableGyroscope(); });
    return promise;
  }
  /**
   * Enable gyroscope events
   */
  enableGyroscope() {
    const promises = [this._stage._controls.initPromise];

    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      promises.push(
        Utils.wrapPermissionPromise(DeviceMotionEvent.requestPermission(), 'DeviceMotionEvent.requestPermission()')
      );
    }

    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      promises.push(
        Utils.wrapPermissionPromise(DeviceOrientationEvent.requestPermission(), 'DeviceOrientationEvent.requestPermission()')
      );
    }

    const promise = Promise.all(promises);

    promise
      .then(() => { this._stage._controls.enableGyroscope(); })
      .catch(() => { this.emit('enableGyroscopeFailure'); });

    return promise;
  }
  /**
   * Toggle widget nearests to the camera and closer than ~35 degrees.
   * Works for widget types: infopoint, transition, project-link
   */
  toggleNearestWidget() {
    this._stage._widgetsManager.toggleNearestWidget(this._stage._camera.getWorldDirection());
  }

  enableWidgets() {
    this._stage.enableWidgets();
  }
  disableWidgets() {
    this._stage.disableWidgets();
  }
  rotateCamera(options) {
    if (!options) return;

    this.loadingPromise.then(() => {
      options.rotation = Utils.parseXYZToRadians(options.rotation);
      this._stage.rotateCamera(options);
    });
  }
  toggleFullscreen() {
    if (this._stage._state.vrState == VrState.PRESENTING || this._stage._state.vrState == VrState.PRESENTING_FULLSCREEN) {
      this._stage.requestExit();
    } else {
      this._ui.requestFullscreen();
    }
  }
  /**
   * Toggles VR mode
   */
  toggleVRMode() {
    this._stage.enterVR();
  }
  renderFrame() {
    return new Promise((resolve) => {
      this._stage.once('render', () => {
        resolve(this._stage._renderer.domElement.toDataURL());
      });
    });
  }
  /**
   * Update players config.
   *
   * @param {Object} newConfig
   * @param {Object} newConfig.baseUrl - url which is prepended to every request
   */
  setConfig(newConfig) {
    if (!newConfig) return;

    if (newConfig.baseUrl) {
      this._config.baseUrl = newConfig.baseUrl;
    }
    if (newConfig.assetUrl) {
      this._config.assetUrl = newConfig.assetUrl;
    }
    if (typeof newConfig.rotateScene === 'boolean') {
      this._config.rotateScene = newConfig.rotateScene;

      if (this._config.rotateScene) {
        this._stage.enableRotateScene();
      } else {
        this._stage.disableRotateScene();
      }
    }
  }
  isVRCompatible() {
    return new Promise((resolve, reject) => {
      this.initPromise.then(() => {
        resolve(this._stage._state.vrEnabled);
      }, () => {
        reject();
      });
    });
  }
  openSpaceplans() {
    this._ui.showSpaceplans();
  }
  closeSpaceplans() {
    this._ui.hideSpaceplans();
  }
  resize() {
    this._stage.onResize();
  }
  /**
   * Sets players branding. Available options 'jll', null (default).
   * Currently sets only loader.
   *
   * @param {string} brandName - brand name
   */
  setBrand(brandName) {
    let brand = (brandName?brandName.toLowerCase():'');

    this._ui.setBrand(brand);
  }
  /**
   * Starts autoplay feature. It works only when there's at least 2 scenes.
   * It will rotate camera from current rotation, up to 90 degrees within 20 seconds.
   * Then it switches scene to the next one. IF last scenes is reached, it will change
   * to first scene and start again.
   * Every user interaction stops this feature.
   *
   * @param {Object} options
   * @param {string} [options.animationLength=20000] - animation length in ms
   * @param {string} [options.rotation=90] - destination rotation in degrees
   */
  startAutoplay(options) {
    if (!this.loadingPromise) return;

    this.loadingPromise.then(() => {
      const {animationLength = 20 * 1000, rotation = 90} = options || {};

      this._stage.startAutoplay({animationLength, rotation: rotation * Math.PI / 180});
    });
  }
  /**
   * Stops autoplay feature.
   */
  stopAutoplay() {
    this._stage.stopAutoplay();
  }
  /**
   * Reset to initial state.
   */
  reset() {
    this._stage.openInistialState();
  }
  /**
   * Returns player statistics
   * @return {Object} info object with players version, renderer info
   */
  info() {
    const i = this._stage._renderer.info;
    const info = {
      version: Config.version.tag,
      branch: Config.version.branch,
      commit: Config.version.commit,
      renderer: {
        geometries: i.memory.geometries,
        textures: i.memory.textures,
      },
    };
    this.emit('info', info);

    return info;
  }
  /**
   * Sets current camera zoom level.
   *
   * @param {Object} options
   * @param {number} options.zoom - Zoom level from 1 to 9
   * @param {boolean} options.animating - If true, zoom will change with animation
   */
  setZoom({zoom = 1, animating = false}) {
    if (!zoom || isNaN(zoom) || zoom < 1 || zoom > 9) {
      this.$l.error('Zoom level must be between 1 and 9');
      return;
    }

    this._stage.setZoom({zoom, animating});
  }
  /**
   * Starts opening link in currently opened widget.
   */
  openCurrentLink() {
    this._stage._widgetsManager.openCurrentLink();
  }
  /**
   * Stops opening link in currently opened widget.
   */
  closeCurrentLink() {
    this._stage._widgetsManager.closeCurrentLink();
  }
  /**
   * Adds new scene. Scene is immidiately available.
   * Remember to add necessary resources before.
   *
   * @param {Object} scene - New scene object to add
   * @return {Promise} Resolved after scene has been added with its widgets.
   */
  addScene(scene) {
    return new Promise((resolve, reject) => {
      const widgets = scene.widgets || [];
      const widgetPromises = [];
      scene.widgets = [];
      Parser.parseScene(scene);

      this._project.addScene(scene);

      for(let i = 0; i < widgets.length; i++) {
        widgetPromises.push(this.addWidget(widgets[i], scene.id));
      }

      Promise.all(widgetPromises).then(resolve, reject);
    });
  }
  /**
   * Removes scene. If this is the current scene, the scene is changed to previous one.
   *
   * @param {Object} options
   * @param {Object} options.sceneId - scene id to remove
   * @return {Promise} Resolved after scene has been removed and changed.
   */
  removeScene(options) {
    return new Promise((resolve, reject) => {
      if (!options.sceneId) {
        this.$l.error('options.sceneId required');
        reject();
      }

      if (this._project._data.scenes.length < 2) {
        this.$l.error('Cannot remove last scene');
        reject();
      }

      const removeScene = () => {
        this._project.removeScene(options.sceneId);
        resolve();
      };

      if (this._stage._state.sceneId === options.sceneId) {
        let scene = this._project.getPreviousScene(options.sceneId);

        if (scene) {
          this.previousScene().then(removeScene, reject);
        } else {
          this.nextScene().then(removeScene, reject);
        }
      } else {
        removeScene();
      }
    });
  }
  /**
   * Update scene.
   * Remember to add necessary resources before and add widgets after.
   *
   * @param {Object} scene - Scene object to update.
   * @return {Promise} Resolved after scene has been updated.
   */
  updateScene(scene) {
    return new Promise((resolve, reject) => {
      if (!scene || !scene.id) {
        this.$l.error('Invalid scene provided.');
        reject();
      }
      const projectScene = this._project.getScene({sceneId: scene.id});
      if (!projectScene) {
        this.$l.error(`Scene with id ${scene.id} doesn't exist.`);
        reject();
      }
      Parser.parseScene(scene);

      // Copy widgets data from previous scene
      scene.widgets = [...projectScene.widgets];

      this._project.updateScene(scene);
      resolve();
    });
  }
}

/**
 * Change scene event.
 *
 * @event Player#changeScene
 * @type {object}
 *
 * @property {string} sceneId - destination sceneId
 * @property {string} sceneName - destination scene name
 * @property {object} rotation - destination rotation {x: rad, y: rad, z: rad}
 * @property {string} [animationType] - possible values: 'fadeToBlack', 'fadeToWhite'
 *
 */

/**
 * Change mode event.
 *
 * @event Player#changeMode
 * @type {object}
 * @property newMode {string} - possible values: 'ready', 'presenting-fullscreen', 'presenting', 'error-no-presentable-displays'
 */

/**
 * Camera rotate event.
 *
 * @event Player#cameraRotate
 * @type {object}
 * @property x {number} - x rotation in degrees
 * @property y {number} - y rotation in degrees
 * @property z {number} - z rotation in degrees
 */

/**
 * Viewport change event.
 *
 * @event Player#cameraViewportChanged
 * @type {object}
 * @property lt {number}
 * @property lb {number}
 * @property rt {number}
 * @property rb {number}
 * @property c {number}
 */

/**
 * Widget position change event.
 *
 * @event Player#widgetPositionChanged
 * @type {object}
 * @property id {string} - widget id
 * @property newRotation {object} - {x: deg, y: deg, z: deg}
 */

/**
 * Click / tap event.
 *
 * @event Player#click
 * @type {object}
 * @property position {string} - click position in 3d scene
 * @property rotation {object} - {x: deg, y: deg, z: deg} - click rotation
 * @property target {string} - possible values 'widget' - exists only if something was clicked on scene
 * @property data {object} - {id: widgetId} - exists only if something was clicked on scene
 * @property effective {boolean} - it's true when this click caused side effect on the scene
 */

/**
 * Close widget event
 *
 * @event Player#closeWidget
 * @type {object}
 * @property widgetId {string} - widget id
 */

/**
 * Open widget event
 *
 * @event Player#openWidget
 * @type {object}
 * @property widgetId {string} - widget id
 */

/**
 * Drag event
 *
 * @event Player#drag
 * @type {object}
 */

/**
 * Open link event. Fired when link was opened.
 *
 * @event Player#openLink
 * @type {object}
 * @property url {string} - url of the link
 * @property text {string} - text displayed to the user
 */
/**
 * Open link event. Fired when link was started to open.
 *
 * @event Player#openLinkStart
 */
/**
 * Open link event. Fired when link has stopped opening.
 *
 * @event Player#openLinkEnd
 */
/**
 * Toggle nearest widget event.
 *
 * @event Player#toggleNearestWidget
 * @type {object}
 * @property effective {boolean} - if its true, near widget was found and was toggled
 * @property target {object}
 * @property target.id {string} - target widget id
 */
/**
 * Info event. Made only for android flat app, cause it couldnt handle return statement.
 *
 * @event Player#info
 * @type {object}
 * @property {Object} info object with players version, renderer info
 */

/**
 * Infopoint link open with url to /p/ or /embed/.
 *
 * @event Player#changePresentation
 * @type {object}
 * @property hash {string} - presentation hash
 * @property presentation {bool} - optional. if true, url was to /p/
 * @property embed {bool} - optional. if true, url was to /embed/
 */

export default Player;

